import React, { useEffect } from "react";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";

function Middle() {
    useEffect(() => {
        deleteItem()
    }, [])
    function deleteItem() {
        const element = document.getElementById('tradingview_widget_wrapper');

        const secondChild = element.children[1].classList.add("removve-item");

    }

    return (

        <div className="container">

            <div className="mt-2 "><AdvancedRealTimeChart theme="light" height={400} width="100%"></AdvancedRealTimeChart></div>

        </div>

    )
}

export default Middle