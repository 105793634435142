import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import URL from "../config/config.json";
import footer from "../assets/images/footer.png";

function Portfolio() {

    const [data, setData] = useState();
    const token = localStorage.getItem("tkn");
    const [activeIndex, setActiveIndex] = useState(1);
  

    useEffect(() => {
        axios.get(URL[0].getOrder, {
            headers: {
                "Authorization": "Bearer " + token
            }
        }).then(res => {
            setData(res.data.orders);
        });

    }, []);



    return (
        <div class="">
            <Navbar />
            <div id="content-container">
                <div class="ui container">
                    <div class="ui stackable one column grid">
                        <div class="column">
                            <div class="ui piled segment">
                                <span class="ui blue ribbon label"><i class="travel icon"></i> Portfolio</span>
                            </div>
                        </div>
                        <div class="column">
                            <div id="portfolio-menu" class="ui pointing  blue menu">
                                <a class={`${activeIndex===0 ?"active item":"item"}`} onClick={() => setActiveIndex(0)}>Positionen</a>
                                <a class={`${activeIndex===1 ?"active item":"item"}`} onClick={() => setActiveIndex(1)}>Trades</a>
                                {/*             <a class="item" onClick={() => setActiveIndex(2)}>Anleihen</a> */}
                                <a class={`${activeIndex===3 ?"active item":"item"}`} onClick={() => setActiveIndex(3)}>Festgeld</a>
                                <a class={`${activeIndex===4 ?"active item":"item"}`} onClick={() => setActiveIndex(4)}>IPOs</a>
                                {/*     <a class="item" onClick={() => setActiveIndex(5)}>Fonds</a> */}
                            </div>

                            {activeIndex === 0 ? <div id="tab-positions" class="ui active tab" >
                                <div id="positions-list_wrapper" class="dataTables_wrapper">
                                    <div class="dt-buttons"></div>

                                    <table id="positions-list" class="ui sortable celled blue table dataTable" role="grid" aria-describedby="positions-list_info" style={{ display: "table" }}>
                                        <thead>
                                            <tr role="row">
                                                <th class="left aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Symbol: activate to sort column ascending" style={{ width: "72.8125px" }}>Symbol</th>
                                                <th class="left aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Börse Aktien Name: activate to sort column ascending" style={{ width: "188.703px" }}>Börse Aktien Name</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Stück: activate to sort column ascending" style={{ width: "42.8594px" }}>Stück</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Aktuell: activate to sort column ascending" style={{ width: "54.8438px" }}>Aktuell</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Währung: activate to sort column ascending" style={{ width: "68.8125px" }}>Währung</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="FX-Rate: activate to sort column ascending" style={{ width: "58.8438px" }}>FX-Rate</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Kaufwert: activate to sort column ascending" style={{ width: "68.8281px" }}>Kaufwert</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Aktueller Wert: activate to sort column ascending" style={{ width: "108.75px" }}>Aktueller Wert</th>
                                                <th class="center aligned sorting_desc" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Realisierter G/V: activate to sort column ascending" style={{ width: "116.734px" }} aria-sort="descending">Realisierter G/V</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="positions-list" rowspan="1" colspan="1" aria-label="Realisierter G/V: activate to sort column ascending" style={{ width: "116.812px" }}>Realisierter G/V</th></tr>
                                        </thead>
                                        <tbody>
                                            {data?.map(res => {
                                                return (
                                                    <tr role="row" class="odd"><td><div class="ui labeled button" tabindex="0">
                                                        <a class="ui right pointing blue basic label">[{res.ticker.ticker}]</a>
                                                    </div>
                                                    </td>
                                                        <td class="left aligned" >{res.ticker_name}</td>
                                                        <td class="center aligned" >{res.volume}</td>
                                                        <td class="center aligned live-data" data-field="regularMarketPrice" data-format="fmtDecimal">{res.price}</td>
                                                        <td class="center aligned"></td>
                                                        <td class="center aligned currency-USDEUR live-data" data-symbol="USDEUR=X" data-field="regularMarketPrice" data-format="fmtLongDecimal">{res.fx_rt}</td>
                                                        <td class="center aligned historical-cost" >{res.purchase_value}</td>
                                                        <td class="center aligned live-data market-value" data-field="regularMarketPrice" >3.111,27</td>
                                                        <td class="center aligned live-data unrealized-pnl red sorting_1" data-symbol="AAPL" data-field="regularMarketPrice" data-format="fmtUnrealizedPnL" data-format-args="regularMarketPrice,25,USD,EUR,3174.7,1,abs">--</td>
                                                        <td class="center aligned live-data unrealized-pnl-pct red" data-symbol="AAPL" data-field="regularMarketPrice" data-format="fmtUnrealizedPnL" data-format-args="regularMarketPrice,25,USD,EUR,3174.7,1,pct">--</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>

                                    </table>
                                </div>
                                <div class="ui piled segment ignored info icon message">
                                    <i class="info circle icon"></i>
                                    <div class="content">
                                        <h3 class="header">Lock-up Zeitraum.</h3>
                                        <p>Die Lock-Up-Frist umfasst den Zeitraum, in dem die Altaktionäre nach dem Börsengang keine Aktien aus ihren Beständen verkaufen dürfen. Dadurch sollen Kurseinbrüche nach dem Börsengang durch den Verkauf von großen Aktienpaketen der Altaktionäre verhindert werden. Erst nach Ablauf dieser Lock-Up-Frist dürfen die Altaktionäre ihre Aktien an der Börse verkaufen.</p>
                                    </div>
                                </div>

                            </div> : null}

                            {activeIndex === 1 ? <div id="tab-trades" class="ui tab active" >
                                <div id="trades-list_wrapper" class="dataTables_wrapper">
                                    <div class="dt-buttons"></div><div id="trades-list_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="" placeholder="" aria-controls="trades-list" />
                                        </label>
                                    </div>
                                    <table id="trades-list" class="ui sortable celled blue table dataTable" role="grid" aria-describedby="trades-list_info">
                                        <thead>
                                            <tr role="row">
                                                <th class="sorting_desc" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-sort="descending" aria-label="Symbol: activate to sort column ascending" style={{ width: "80.8125px" }}>Symbol</th>
                                                <th class="sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="Name: activate to sort column ascending" style={{ width: "211.672px" }}>Name</th>
                                                <th class="sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="Aktion: activate to sort column ascending" style={{ width: "83.9375px" }}>Aktion</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="Stück: activate to sort column ascending" style={{ width: "48.5625px" }}>Stück</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="Preis: activate to sort column ascending" style={{ width: "55.4688px" }}>Preis</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="FX-Rate: activate to sort column ascending" style={{ width: "66.0625px" }}>FX-Rate</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="Gesamt: activate to sort column ascending" style={{ width: "73.6406px" }}>Gesamt</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="Laufzeitbeginn: activate to sort column ascending" style={{ width: "120.859px" }}>Laufzeitbeginn</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="Laufzeitende: activate to sort column ascending" style={{ width: "105.688px" }}>Laufzeitende</th>
                                                <th class="center aligned sorting" tabindex="0" aria-controls="trades-list" rowspan="1" colspan="1" aria-label="Dauer: activate to sort column ascending" style={{ width: "51.2969px" }}>Dauer</th></tr>
                                        </thead>
                                        <tbody>
                                            {data?.map(res => {
                                                return (
                                                    <tr class="negative odd" role="row">
                                                        <td class="sorting_1">
                                                            <div class="ui labeled button" tabindex="0">
                                                                <a class="ui right pointing blue basic label">{res.ticker.ticker}</a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {res.ticker_name}
                                                            <div class="trade-exchange">
                                                                {res.ticker.primary_exchange}
                                                            </div>
                                                        </td>
                                                        <td>{res.volume > 0 ? "Kaufen" : "Verkaufen"}</td>
                                                        <td class="center aligned" >{res.volume}</td>
                                                        <td class="center aligned" >{res.price}</td>
                                                        <td class="center aligned" >{res.fx_rate}</td>
                                                        <td class="center aligned" >{res.purchase_value}</td>
                                                        <td class="center aligned" >--</td>
                                                        <td class="center aligned" >--</td>
                                                        <td class="center aligned" data-order="null"></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>

                                    </table>

                                </div>
                                <div class="ui piled segment ignored info icon message">
                                    <i class="info circle icon"></i>
                                    <div class="content">
                                        <h3 class="header">Lock-up Zeitraum.</h3>
                                        <p>Die Lock-Up-Frist umfasst den Zeitraum, in dem die Altaktionäre nach dem Börsengang keine Aktien aus ihren Beständen verkaufen dürfen. Dadurch sollen Kurseinbrüche nach dem Börsengang durch den Verkauf von großen Aktienpaketen der Altaktionäre verhindert werden. Erst nach Ablauf dieser Lock-Up-Frist dürfen die Altaktionäre ihre Aktien an der Börse verkaufen.</p>
                                    </div>
                                </div>

                            </div> : null}
                            {/*   {activeIndex === 2 ? <div id="tab-anleihen" class="ui tab" data-tab="tab-anleihen">
                                <div id="modal-anleihen-notes" class="ui large action modal">
                                    <i class="close icon"></i>
                                    <div class="header">
                                        Anmerkungen
                                    </div>
                                    <div class="content">
                                        <div class="notes-area"></div>
                                    </div>
                                    <div class="actions">
                                        <div class="ui blue cancel button">Schließen</div>
                                    </div>
                                </div>
                                <table id="anleihen-list" class="ui sortable celled blue table">
                                    <thead>
                                        <tr>
                                            <th>Anleihen Name</th>
                                            <th class="center aligned">Stück</th>
                                            <th class="center aligned">Kaufkurs</th>
                                            <th class="center aligned">Kaufwert</th>
                                            <th class="center aligned">Laufzeitbeginn</th>
                                            <th class="center aligned">Laufzeitende</th>
                                            <th class="center aligned">Kupon in %</th>
                                            <th class="center aligned">Wert in <br />EUR</th>
                                            <th class="center aligned">Zinstermin</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                                <div class="ui piled segment ignored info icon message">
                                    <i class="info circle icon"></i>
                                    <div class="content">
                                        <h3 class="header">Lock-up Zeitraum.</h3>
                                        <p>Die Lock-Up-Frist umfasst den Zeitraum, in dem die Altaktionäre nach dem Börsengang keine Aktien aus ihren Beständen verkaufen dürfen. Dadurch sollen Kurseinbrüche nach dem Börsengang durch den Verkauf von großen Aktienpaketen der Altaktionäre verhindert werden. Erst nach Ablauf dieser Lock-Up-Frist dürfen die Altaktionäre ihre Aktien an der Börse verkaufen.</p>
                                    </div>
                                </div>

                            </div> : null} */}
                            {activeIndex === 3 ?
                                <div>

                                    <div id="funds-list_wrapper" class="dataTables_wrapper no-footer">
                                        <div class="dt-buttons"></div>

                                        <table id="funds-list" class="ui sortable celled blue table dataTable no-footer" role="grid" aria-describedby="funds-list_info">
                                            <thead>
                                                <tr role="row">
                                                    <th class="sorting_desc" tabindex="0" aria-controls="funds-list" rowspan="1" colspan="1" aria-sort="descending" aria-label="Festgeld Name: activate to sort column ascending" style={{ width: "165.094px" }}>Festgeld Name</th>
                                                    <th class="center aligned sorting" tabindex="0" aria-controls="funds-list" rowspan="1" colspan="1" aria-label="Anlagesumme: activate to sort column ascending" style={{ width: "160.766px" }}>Anlagesumme</th>
                                                    <th class="center aligned sorting" tabindex="0" aria-controls="funds-list" rowspan="1" colspan="1" aria-label="Zinsen p.a.: activate to sort column ascending" style={{ width: "120.688px" }}>Zinsen p.a.</th>
                                                    <th class="center aligned sorting" tabindex="0" aria-controls="funds-list" rowspan="1" colspan="1" aria-label="Laufzeitbeginn: activate to sort column ascending" style={{ width: "164.266px" }}>Laufzeitbeginn</th>
                                                    <th class="center aligned sorting" tabindex="0" aria-controls="funds-list" rowspan="1" colspan="1" aria-label="Laufzeitende: activate to sort column ascending" style={{ width: "144.516px" }}>Laufzeitende</th>
                                                    <th class="center aligned sorting" tabindex="0" aria-controls="funds-list" rowspan="1" colspan="1" aria-label="Wert in EUR: activate to sort column ascending" style={{ width: "87.7969px" }}>Wert in <br />EUR</th>
                                                    <th class="center aligned sorting" tabindex="0" aria-controls="funds-list" rowspan="1" colspan="1" aria-label="Zinstermin: activate to sort column ascending" style={{ width: "123.875px" }}>Zinstermin</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="">
                                                    <td colspan="7" class=""></td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>

                                    <div class="ui piled segment ignored info icon message">
                                        <i class="info circle icon"></i>
                                        <div class="content">
                                            <h3 class="header">Lock-up Zeitraum.</h3>
                                            <p>Die Lock-Up-Frist umfasst den Zeitraum, in dem die Altaktionäre nach dem Börsengang keine Aktien aus ihren Beständen verkaufen dürfen. Dadurch sollen Kurseinbrüche nach dem Börsengang durch den Verkauf von großen Aktienpaketen der Altaktionäre verhindert werden. Erst nach Ablauf dieser Lock-Up-Frist dürfen die Altaktionäre ihre Aktien an der Börse verkaufen.</p>
                                        </div>
                                    </div>
                                </div> : null}
                            {activeIndex === 4 ? <div id="tab-fixed-deposit" class="ui tab active" >
                                <div id="modal-fixed-notes" class="ui large action modal">
                                    <i class="close icon"></i>
                                    <div class="header">
                                        Anmerkungen
                                    </div>
                                    <div class="content">
                                        <div class="notes-area"></div>
                                    </div>
                                    <div class="actions">
                                        <div class="ui blue cancel button">Schließen</div>
                                    </div>
                                </div>

                                <div id="fixed-deposit-list_wrapper" class="dataTables_wrapper no-footer">
                                  
                                   
                                            <table id="fixed-deposit-list" class="ui sortable celled blue table dataTable no-footer" role="grid" aria-describedby="fixed-deposit-list_info">
                                    <thead>
                                        <tr role="row">
                                            <th class="sorting_desc" tabindex="0" aria-controls="fixed-deposit-list" rowspan="1" colspan="1" aria-sort="descending" aria-label="Ipo Name: activate to sort column ascending" style={{width:"158.391px"}}>Ipo Name</th>
                                            <th class="center aligned sorting" tabindex="0" aria-controls="fixed-deposit-list" rowspan="1" colspan="1" aria-label="Stück: activate to sort column ascending" style={{width: "105.344px"}}>Stück</th>
                                            <th class="center aligned sorting" tabindex="0" aria-controls="fixed-deposit-list" rowspan="1" colspan="1" aria-label="Kaufkurs: activate to sort column ascending" style={{width: "154.531px"}}>Kaufkurs</th>
                                            <th class="center aligned sorting" tabindex="0" aria-controls="fixed-deposit-list" rowspan="1" colspan="1" aria-label="Kaufwert: activate to sort column ascending" style={{width: "157.188px"}}>Kaufwert</th>
                                            <th class="center aligned sorting" tabindex="0" aria-controls="fixed-deposit-list" rowspan="1" colspan="1" aria-label="Book Building: activate to sort column ascending" style={{width: "219.5px"}}>Book Building</th>
                                            <th class="center aligned sorting" tabindex="0" aria-controls="fixed-deposit-list" rowspan="1" colspan="1" aria-label="Wert in EUR: activate to sort column ascending" style={{width: "195.047px"}}>Wert in EUR</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="odd">
                                            <td valign="top" colspan="6" class="dataTables_empty"></td>
                                            </tr>
                                            </tbody>
                                </table>
                             
                               </div>

                                <div class="ui piled segment ignored info icon message">
                                    <i class="info circle icon"></i>
                                    <div class="content">
                                        <h3 class="header">Lock-up Zeitraum.</h3>
                                        <p>Die Lock-Up-Frist umfasst den Zeitraum, in dem die Altaktionäre nach dem Börsengang keine Aktien aus ihren Beständen verkaufen dürfen. Dadurch sollen Kurseinbrüche nach dem Börsengang durch den Verkauf von großen Aktienpaketen der Altaktionäre verhindert werden. Erst nach Ablauf dieser Lock-Up-Frist dürfen die Altaktionäre ihre Aktien an der Börse verkaufen.</p>
                                    </div>
                                </div>

                            </div> : null}
                         {/*    <div id="tab-fonds" class="ui tab" data-tab="tab-fonds">
                                <div id="modal-fonds-notes" class="ui large action modal">
                                    <i class="close icon"></i>
                                    <div class="header">
                                        Anmerkungen
                                    </div>
                                    <div class="content">
                                        <div class="notes-area"></div>
                                    </div>
                                    <div class="actions">
                                        <div class="ui blue cancel button">Schließen</div>
                                    </div>
                                </div>

                                <table id="fonds-list" class="ui sortable celled blue table">
                                    <thead>
                                        <tr>
                                            <th>Fund Name</th>
                                            <th class="center aligned">Stück</th>
                                            <th class="center aligned">Kaufkurs</th>
                                            <th class="center aligned">Kaufwert</th>
                                            <th class="center aligned">Book Building</th>
                                            <th class="center aligned">Wert in EUR</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>

                                <div class="ui piled segment ignored info icon message">
                                    <i class="info circle icon"></i>
                                    <div class="content">
                                        <h3 class="header">Lock-up Zeitraum.</h3>
                                        <p>Die Lock-Up-Frist umfasst den Zeitraum, in dem die Altaktionäre nach dem Börsengang keine Aktien aus ihren Beständen verkaufen dürfen. Dadurch sollen Kurseinbrüche nach dem Börsengang durch den Verkauf von großen Aktienpaketen der Altaktionäre verhindert werden. Erst nach Ablauf dieser Lock-Up-Frist dürfen die Altaktionäre ihre Aktien an der Börse verkaufen.</p>
                                    </div>
                                </div>

                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
            <div class="ui inverted vertical footer segment">
                <div class="ui center aligned container">
                    <div class="ui inverted section divider"></div>
                    <div class="tablet-and-below-center column"><h4 class="ui inverted copyright header"><img src={footer} class="ui tinyup image" />
                        FGI Financial Depot. I ® Registered Trademark. © 2022. Alle Rechte vorbehalten.
                        <div class="ui inverted section divider"></div>
                    </h4></div>


                </div>
            </div>
        </div>
    )
}

export default Portfolio