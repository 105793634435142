import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Trade from './pages/Trade';
import VerifyEmail from './components/register/verifyEmail';
import Portfolio from './pages/Portfolio';
import Profile from './pages/Profile';

function App() {


  return (
    <div className="App">

      <Routes>
      <Route path='/' element={<Home/>}/>
        <Route path='login' element={<Login/>}/>
        <Route path='register' element={<Register/>}/>
        <Route path='trade' element={<Trade/>}/>
        <Route path='verify' element={<VerifyEmail/>}/>
        <Route path='portfolio' element={<Portfolio/>}/>
        <Route path='profile' element={<Profile/>}/>
      </Routes>
      
    </div>
  );
}

export default App;
