import axios from "axios";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../components/Navbar/Navbar";
import URL from "../config/config.json";
import {FundamentalData} from "react-ts-tradingview-widgets";
import { FaMoneyBill } from 'react-icons/fa';
import footer from "../assets/images/footer.png";

function Trade() {
    const [data, setData] = useState();
    const [tickerData, setTickerData] = useState("AAPL")
    const [data1, setData1] = useState();
    const [volume, setVolume] = useState("0");
    const token = localStorage.getItem("tkn");
    const isLogin = localStorage.getItem("isLogin");
    console.log(data)

    function searchTrade(e) {
        axios.post(URL[0].tradeSearch, {
            search: e.target.value
        }).then(res => setData(res.data));

    }

    function buy() {

        if (isLogin === "true") {

            axios.post(URL[0].tradeBuy, {
                ticker: data1?.ticker,
                volume: volume,
                price: String(data1?.price),
                currency: "eur"
            },
                {
                    headers: {
                        "Authorization": "Bearer " + token
                    }
                }
            ).then(res => {
                toast.success(res.data.message);
            }).catch(err => {
                toast.error(err.response.data.message);
            });
        }
        else {
            toast.error("Please login first")
        }
    }

    function sell() {

        if (isLogin === "true") {
            axios.post(URL[0].tradeSell, {
                ticker: data1?.ticker,
                volume: volume,
                price: String(data1?.price),
                currency: "eur"
            }, {
                headers: {
                    "Authorization": "Bearer " + token
                }
            }
            ).then(res => {
                toast.success(res.data.message);
            }).catch(err => {
                toast.error(err.response.data.message);
            });;
        }

    }

    useEffect(() => {
        axios.get(`${URL[0].tradeDetail + tickerData}`, {
            ticker: tickerData
        }).then(res => setData1(res.data))
    }, [tickerData]);

    return (
    <><Navbar/>
    <ToastContainer/>
        <div id="content-container" className="mt-2">
        <div class="ui container">
      <div class="ui stackable one column grid">
            <div class="column">
        <div class="ui piled segment">
          <span class="ui blue ribbon label"><FaMoneyBill size={24} /> Handel</span>
          
          </div>
          
        </div>
        <div class="column">
          <div class="ui stackable grid">
            <div class="eight wide column">
                <form id="modal-limit-order" name="limit-order-form" class="ui limit-order-form skip-submission-check tiny form action modal" data-action="add">
                    <i class="close icon"></i>
                    <div class="header">
                      Order Platzieren
                    </div>
                    <div class="ui piled segment">
                        <div class="ui stackable two column grid">
                            <div class="column">
                                <div class="item"><span class=" value live-data selected-symbol" data-symbol="AAPL" data-field="quoteType" data-format="fmtString">EQUITY</span><span class="dot"></span><span class="symbol value">AAPL</span></div>
                                <div class="item header"><span class="short-name live-data selected-symbol" data-symbol="AAPL" data-field="shortName">Apple Inc. - Common Stock</span></div>
                                <div class="item"><span class="stock-exchange-name ui header"><i class="us flag"></i>NASDAQ</span></div>
                            </div>
                            <div class="column right aligned">
                                <div class="item"><span class="live-data selected-symbol" data-symbol="AAPL" data-format="fmtDate" data-field="regularMarketTime">14.01.2023</span></div>
                                <div class="item header"><span class="current-price value live-data selected-symbol" data-symbol="AAPL" data-field="regularMarketPrice" data-format="fmtDecimal" data-callback="clbEnableDisableTrading" data-check-trading="true">134,76</span>&nbsp;<span class=" value live-data selected-symbol" data-symbol="AAPL" data-field="currency" data-format="fmtString">USD</span></div>
                                <div class="item"><span class=" value live-data selected-symbol" data-symbol="AAPL" data-field="regularMarketChangePercent" data-format="fmtUpDownIndicatorWithPercent" data-format-args="regularMarketChange,regularMarketChangePercent"><i class="green caret up large icon"></i>1.01 %</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="ui error message"></div>
                        <div class="ui result message"></div>
                        <input type="hidden" name="user" value="1285"/>
                        <div class="two fields">
                            <div class="field">
                                <div class="ui input select">
                                    <input class="buy" type="radio" id="type-buy" name="order_type" value="buy" checked="" tabindex="0"/>
                                    <label class="buy" for="type-buy">KAUF</label>
                                    <input class="sell" type="radio" id="type-sell" name="order_type" value="sell"/>
                                    <label class="sell" for="type-sell">VERKAUF</label>
                                </div>                 
                            </div>
                            <div class="required field">
                              <div class="ui input">
                                <div class="ui ordertime-type fluid selection dropdown" tabindex="0">
                                    <input type="hidden" name="ordertime_type"/>
                                    <i class="dropdown icon"></i>
                                    <div class="default text">Bestellzeittyp</div>
                                    <div class="menu">
                                        <div class="item" data-value="d">Tagesgültig</div>
                                        <div class="item" data-value="u">Unbefristet</div>
                                    </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="two fields">
                            <div class="required field">
                              <div class="ui input">
                                <div class="ui limit-type fluid selection dropdown" tabindex="0">
                                    <input type="hidden" name="limit_type" value="0"/>
                                    <i class="dropdown icon"></i>
                                    <div class="default text"></div>
                                    <div class="menu">
                                        <div class="item" data-value="0">Limit</div>
                                        <div class="item" data-value="1">Market</div>
                                        <div class="item" data-value="2">Stop Loss</div>
                                        <div class="item" data-value="3">Stop Limit</div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="limit-entries required field">
                              <div class="ui limit-price input right icon">
                                <input type="text" tabindex="0" name="limit_price" value="" placeholder="Limit preis"/>
                                <i class="euro icon"></i>
                              </div>
                              <div class="ui stop-price input right icon">
                                <input type="text" tabindex="0" name="stop_price" value="" placeholder="Stop preis"/>
                                <i class="euro icon"></i>
                              </div>
                              <div class="ui market-amount input">
                                <input type="text" tabindex="0" name="market_amount" value="" placeholder="Anzahl"/>
                              </div>
                            </div>
                        </div>
                        <div class="two fields">
                            <div class="required field">
                              <div class="ui limit-amount input">
                                <input type="text" tabindex="0" name="limit_amount" value="" placeholder="Anzahl"/>
                              </div>
                            </div>
                            <div class="required field">
                              <div class="ui total input right icon">
                                <input type="text" tabindex="0" name="total" value="" placeholder="Betrag"/>
                                <i class="euro icon"></i>
                              </div>
                            </div>
                        </div>
                        <div class="field">
                            <p><b>Hinweis</b> Für jedes Wertpapier in Ihrem Depotbestand können Sie hier Kurslimits setzen. Wird eines der Limits erreicht, benachrichtigen wir Sie per E-Mail.</p>
                        </div>
                    </div>
                    <div class="actions">
                        <div class="ui large left labeled icon cancel button left floated"><i class="cancel icon"></i> Abbrechen </div>
                        <div class="ui large blue right labeled icon submit button ">Speichern <i class="checkmark icon"></i></div>
                    </div>
                </form>
                <div id="symbol-search" class="ui search">
                    <div class="ui icon input">
                      <input class="prompt" type="text" placeholder="Symbol oder Unternehmen..."  data-exchange-code="NASDAQ" autocomplete="off" onChange={(e)=>searchTrade(e)}/>
                    
                    </div>
                   <div class="results transition visible" style={{display: "block !important"}}>
                {data?.map(res=>{
                    return(
                        <a class="result" onClick={() => { setTickerData(res.ticker); setData(undefined) }}>
                        <div class="content">
                        <div class="price">{res.primary_exchange}</div>
                        <div class="title">{res.ticker}</div>
                        <div class="description">{res.name}</div>
                        </div>
                        </a>
                    )
                })}
                 </div>
                </div>
                
            </div>
            <div class="eight wide right aligned column">
                        </div>
            <div class="center aligned sixteen wide column">
              <h2 id="stock-name-header" class="ui header live-data selected-symbol" data-symbol="AAPL" data-field="shortName">{data1?.name}</h2>
              <h4 id="stock-exchange-name" class="ui header">{data1?.primary_exchange}</h4>
                            <span class="negative ui button">OPEN</span>
                
                        <div>
                <div id="symbol-last-trade-price" class="ui statistic">
                  <div class="value live-data selected-symbol" data-field="regularMarketPrice" data-format="fmtDecimal" data-callback="clbEnableDisableTrading" data-check-trading="true"><span style={{ fontSize: "30px" }}>{data1?.price} <span style={{ color: `${Number(data1?.change) > 0 ? "green" : "red"}` }}>{data1?.change}</span> <span style={{ color: `${Number(data1?.changePercent) > 0 ? "green" : "red"}` }}>({(Number(data1?.changePercent) * 100).toFixed(2)}%)</span>  </span></div>
               
                </div>
           
             
              </div>
            </div>
            <div class="center aligned sixteen wide column">
             
              <div id="trade-form" class="ui form" style={{display:"block"}}>
                <div class="fields">
                  <div class="two wide trade-quantity field">
                    <input id="trade-quantity" name="quantity" placeholder="Menge" type="text" onChange={(e) => setVolume(e.target.value)} />
                  </div>
                </div>
                <div class="ui big buttons">
                  <button class="ui positive left labeled icon trade button" onClick={() => buy()} ><FaMoneyBill className="icon"  />Kaufen</button>
                  <div class="or"></div>
                  <button class="ui negative trade button" data-action="sell" onClick={() => sell()}>Verkaufen</button>
                
            
    
                </div>
              </div>
            </div>
          </div>
    
              <div id="stock-tabs" class="ui stackable one column grid">
                <div class="column">
              <div id="stock-tabs-menu" class="ui pointing  blue menu">
                <a class="active item" data-tab="tab-market-data">Markt Daten</a>
              </div>
    
              <FundamentalData symbol={tickerData} width={"100%"}  colorTheme="light"></FundamentalData>
            </div>
        </div>
      </div>
    </div>
      </div>
      <div class="ui inverted vertical footer segment">
        <div class="ui center aligned container">
            <div class="ui inverted section divider"></div>
            <div class="tablet-and-below-center column"><h4 class="ui inverted copyright header"><img src={footer} class="ui tinyup image"/>
            FGI Financial Depot. I ® Registered Trademark. © 2022. Alle Rechte vorbehalten.
          <div class="ui inverted section divider"></div>
        </h4></div>
    
    
    </div>
    </div>
    </div>
    </>
    )
}

export default Trade