import React, { useEffect } from "react";

import { TickerTape} from "react-ts-tradingview-widgets";

function First() {

    useEffect(() => {
        deleteItem()
    }, []);

    function deleteItem() {
        const element = document.getElementById('tradingview_widget_wrapper');

        const secondChild = element.children[1].classList.add("removve-item");

    }

    return (
      
            <div className="container">
                <div className=" mt-2">
                <TickerTape  colorTheme="light"></TickerTape>
                </div>

            </div>
  
    )
}

export default First