import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import URL from "../config/config.json";
import footer from "../assets/images/footer.png";
import { FaUser } from 'react-icons/fa';
import avatar from "../assets/images/default_avatar.png"



const Profile = () => {

  const [data, setData] = useState();
  const token = localStorage.getItem("tkn");

  useEffect(() => {
    axios.get(URL[0].user, {
      headers: {
        "Authorization": "Bearer " + token
      }
    }).then(res => {

      setData(res.data);
      localStorage.setItem("balance", res.data?.wallet[0]?.amount);
      localStorage.setItem("username",res.data?.username);
    });



  }, []);

  return (
    <>
      <Navbar />
      <div id="content-container">
        <div class="ui container">
          <div class="left aligned sixteen wide column">
            <div class="column">

              <div class="ui piled segment">
                <span class="ui blue ribbon label"><FaUser /> Benutzer Profil
                </span>
              </div>

            </div>
            <div class="ui piled segment">
              <form class="ui form" method="POST" action="/profile" enctype="multipart/form-data">
                <div class="ui stackable two column grid">
                  <div class="four wide column">
                    <div class="field">
                      <label>Avatar</label>
                      <div id="profile-avatar">
                        <img class="ui medium bordered image" src={avatar} />

                      </div>
                      <div>
                        <input type="file" name="avatar" />
                      </div>
                    </div>
                  </div>
                  <div class="twelve wide column">
                    <div class="disabled field">
                      <label>Vorname</label>
                      <input type="text" name="" value={data?.username} />
                    </div>
                  
                    <div class="disabled field">
                      <label>E-Mail</label>
                      <input type="text" name="email" value={data?.email} />
                    </div>
                    <div class="field">
                      <label>Mobiltelefon</label>
                      <input type="text" name="phone" defaultValue={data?.phone} placeholder="Mobiltelefon" />
                    </div>
               
                    
                    <h5 class="ui dividing header">Adresse</h5>
                    <div class="two fields">
                      <div class="field">
                        <div class="ui input">
                          <input type="text" name="street_nr" defaultValue={data?.country} placeholder="der Heimat" />
                        </div>
                      </div>
                      <div class="field">
                        <div class="ui input">
                          <input type="text" name="post_nr" defaultValue={data?.city} placeholder="Stadt" />
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <div class="ui input">
                        <input type="text" name="town" defaultValue={data?.address} placeholder="die Anschrift"/>
                      </div>
                    </div>
                    <div class="field">
                      <label>Timezone</label>
                      <div class="ui search selection dropdown"><select id="timezone-dropdown" name="timezone">
                        <option
                          value="Africa/Abidjan">
                          Africa/Abidjan
                        </option>
                        <option value="Africa/Accra">Africa/Accra</option>
                        <option value="Africa/Addis_Ababa">Africa/Addis_Ababa</option>
                        <option value="Africa/Algiers">Africa/Algiers</option>
                        <option value="Africa/Asmara">Africa/Asmara</option>
                        <option value="Africa/Asmera">Africa/Asmera</option>
                        <option value="Africa/Bamako">Africa/Bamako</option>
                        <option value="Africa/Bangui">Africa/Bangui</option>
                        <option value="Africa/Banjul">Africa/Banjul</option>
                        <option value="Africa/Bissau">Africa/Bissau</option>
                        <option value="Africa/Blantyre">Africa/Blantyre</option>
                        <option value="Africa/Brazzaville">Africa/Brazzaville</option>
                        <option value="Africa/Bujumbura">Africa/Bujumbura</option>
                        <option value="Africa/Cairo">Africa/Cairo</option>
                        <option value="Africa/Casablanca">Africa/Casablanca</option>
                        <option value="Africa/Ceuta">Africa/Ceuta</option>
                        <option value="Africa/Conakry">Africa/Conakry</option>
                        <option value="Africa/Dakar">Africa/Dakar</option>
                        <option value="Africa/Dar_es_Salaam">Africa/Dar_es_Salaam</option>
                        <option value="Africa/Djibouti">Africa/Djibouti</option>
                        <option value="Africa/Douala">Africa/Douala</option>
                        <option value="Africa/El_Aaiun">Africa/El_Aaiun</option>
                        <option value="Africa/Freetown">Africa/Freetown</option>
                        <option value="Africa/Gaborone">Africa/Gaborone</option>
                        <option value="Africa/Harare">Africa/Harare</option>
                        <option value="Africa/Johannesburg">Africa/Johannesburg</option>
                        <option value="Africa/Juba">Africa/Juba</option>
                        <option value="Africa/Kampala">Africa/Kampala</option>
                        <option value="Africa/Khartoum">Africa/Khartoum</option>
                        <option value="Africa/Kigali">Africa/Kigali</option>
                        <option value="Africa/Kinshasa">Africa/Kinshasa</option>
                        <option value="Africa/Lagos">Africa/Lagos</option>
                        <option value="Africa/Libreville">Africa/Libreville</option>
                        <option value="Africa/Lome">Africa/Lome</option>
                        <option value="Africa/Luanda">Africa/Luanda</option>
                        <option value="Africa/Lubumbashi">Africa/Lubumbashi</option>
                        <option value="Africa/Lusaka">Africa/Lusaka</option>
                        <option value="Africa/Malabo">Africa/Malabo</option>
                        <option value="Africa/Maputo">Africa/Maputo</option>
                        <option value="Africa/Maseru">Africa/Maseru</option>
                        <option value="Africa/Mbabane">Africa/Mbabane</option>
                        <option value="Africa/Mogadishu">Africa/Mogadishu</option>
                        <option value="Africa/Monrovia">Africa/Monrovia</option>
                        <option value="Africa/Nairobi">Africa/Nairobi</option>
                        <option value="Africa/Ndjamena">Africa/Ndjamena</option>
                        <option value="Africa/Niamey">Africa/Niamey</option>
                        <option value="Africa/Nouakchott">Africa/Nouakchott</option>
                        <option value="Africa/Ouagadougou">Africa/Ouagadougou</option>
                        <option value="Africa/Porto-Novo">Africa/Porto-Novo</option>
                        <option value="Africa/Sao_Tome">Africa/Sao_Tome</option>
                        <option value="Africa/Timbuktu">Africa/Timbuktu</option>
                        <option value="Africa/Tripoli">Africa/Tripoli</option>
                        <option value="Africa/Tunis">Africa/Tunis</option>
                        <option value="Africa/Windhoek">Africa/Windhoek</option>
                        <option value="America/Adak">America/Adak</option>
                        <option value="America/Anchorage">America/Anchorage</option>
                        <option value="America/Anguilla">America/Anguilla</option>
                        <option value="America/Antigua">America/Antigua</option>
                        <option value="America/Araguaina">America/Araguaina</option>
                        <option value="America/Argentina/Buenos_Aires">America/Argentina/Buenos_Aires</option>
                        <option value="America/Argentina/Catamarca">America/Argentina/Catamarca</option>
                        <option value="America/Argentina/ComodRivadavia">America/Argentina/ComodRivadavia</option>
                        <option value="America/Argentina/Cordoba">America/Argentina/Cordoba</option>
                        <option value="America/Argentina/Jujuy">America/Argentina/Jujuy</option>
                        <option value="America/Argentina/La_Rioja">America/Argentina/La_Rioja</option>
                        <option value="America/Argentina/Mendoza">America/Argentina/Mendoza</option>
                        <option value="America/Argentina/Rio_Gallegos">America/Argentina/Rio_Gallegos</option>
                        <option value="America/Argentina/Salta">America/Argentina/Salta</option>
                        <option value="America/Argentina/San_Juan">America/Argentina/San_Juan</option>
                        <option value="America/Argentina/San_Luis">America/Argentina/San_Luis</option>
                        <option value="America/Argentina/Tucuman">America/Argentina/Tucuman</option>
                        <option value="America/Argentina/Ushuaia">America/Argentina/Ushuaia</option>
                        <option value="America/Aruba">America/Aruba</option>
                        <option value="America/Asuncion">America/Asuncion</option>
                        <option value="America/Atikokan">America/Atikokan</option>
                        <option value="America/Atka">America/Atka</option>
                        <option value="America/Bahia">America/Bahia</option>
                        <option value="America/Bahia_Banderas">America/Bahia_Banderas</option>
                        <option value="America/Barbados">America/Barbados</option>
                        <option value="America/Belem">America/Belem</option>
                        <option value="America/Belize">America/Belize</option>
                        <option value="America/Blanc-Sablon">America/Blanc-Sablon</option>
                        <option value="America/Boa_Vista">America/Boa_Vista</option>
                        <option value="America/Bogota">America/Bogota</option>
                        <option value="America/Boise">America/Boise</option>
                        <option value="America/Buenos_Aires">America/Buenos_Aires</option>
                        <option value="America/Cambridge_Bay">America/Cambridge_Bay</option>
                        <option value="America/Campo_Grande">America/Campo_Grande</option>
                        <option value="America/Cancun">America/Cancun</option>
                        <option value="America/Caracas">America/Caracas</option>
                        <option value="America/Catamarca">America/Catamarca</option>
                        <option value="America/Cayenne">America/Cayenne</option>
                        <option value="America/Cayman">America/Cayman</option>
                        <option value="America/Chicago">America/Chicago</option>
                        <option value="America/Chihuahua">America/Chihuahua</option>
                        <option value="America/Coral_Harbour">America/Coral_Harbour</option>
                        <option value="America/Cordoba">America/Cordoba</option>
                        <option value="America/Costa_Rica">America/Costa_Rica</option>
                        <option value="America/Creston">America/Creston</option>
                        <option value="America/Cuiaba">America/Cuiaba</option>
                        <option value="America/Curacao">America/Curacao</option>
                        <option value="America/Danmarkshavn">America/Danmarkshavn</option>
                        <option value="America/Dawson">America/Dawson</option>
                        <option value="America/Dawson_Creek">America/Dawson_Creek</option>
                        <option value="America/Denver">America/Denver</option>
                        <option value="America/Detroit">America/Detroit</option>
                        <option value="America/Dominica">America/Dominica</option>
                        <option value="America/Edmonton">America/Edmonton</option>
                        <option value="America/Eirunepe">America/Eirunepe</option>
                        <option value="America/El_Salvador">America/El_Salvador</option>
                        <option value="America/Ensenada">America/Ensenada</option>
                        <option value="America/Fort_Nelson">America/Fort_Nelson</option>
                        <option value="America/Fort_Wayne">America/Fort_Wayne</option>
                        <option value="America/Fortaleza">America/Fortaleza</option>
                        <option value="America/Glace_Bay">America/Glace_Bay</option>
                        <option value="America/Godthab">America/Godthab</option>
                        <option value="America/Goose_Bay">America/Goose_Bay</option>
                        <option value="America/Grand_Turk">America/Grand_Turk</option>
                        <option value="America/Grenada">America/Grenada</option>
                        <option value="America/Guadeloupe">America/Guadeloupe</option>
                        <option value="America/Guatemala">America/Guatemala</option>
                        <option value="America/Guayaquil">America/Guayaquil</option>
                        <option value="America/Guyana">America/Guyana</option>
                        <option value="America/Halifax">America/Halifax</option>
                        <option value="America/Havana">America/Havana</option>
                        <option value="America/Hermosillo">America/Hermosillo</option>
                        <option value="America/Indiana/Indianapolis">America/Indiana/Indianapolis</option>
                        <option value="America/Indiana/Knox">America/Indiana/Knox</option>
                        <option value="America/Indiana/Marengo">America/Indiana/Marengo</option>
                        <option value="America/Indiana/Petersburg">America/Indiana/Petersburg</option>
                        <option value="America/Indiana/Tell_City">America/Indiana/Tell_City</option>
                        <option value="America/Indiana/Vevay">America/Indiana/Vevay</option>
                        <option value="America/Indiana/Vincennes">America/Indiana/Vincennes</option>
                        <option value="America/Indiana/Winamac">America/Indiana/Winamac</option>
                        <option value="America/Indianapolis">America/Indianapolis</option>
                        <option value="America/Inuvik">America/Inuvik</option>
                        <option value="America/Iqaluit">America/Iqaluit</option>
                        <option value="America/Jamaica">America/Jamaica</option>
                        <option value="America/Jujuy">America/Jujuy</option>
                        <option value="America/Juneau">America/Juneau</option>
                        <option value="America/Kentucky/Louisville">America/Kentucky/Louisville</option>
                        <option value="America/Kentucky/Monticello">America/Kentucky/Monticello</option>
                        <option value="America/Knox_IN">America/Knox_IN</option>
                        <option value="America/Kralendijk">America/Kralendijk</option>
                        <option value="America/La_Paz">America/La_Paz</option>
                        <option value="America/Lima">America/Lima</option>
                        <option value="America/Los_Angeles">America/Los_Angeles</option>
                        <option value="America/Louisville">America/Louisville</option>
                        <option value="America/Lower_Princes">America/Lower_Princes</option>
                        <option value="America/Maceio">America/Maceio</option>
                        <option value="America/Managua">America/Managua</option>
                        <option value="America/Manaus">America/Manaus</option>
                        <option value="America/Marigot">America/Marigot</option>
                        <option value="America/Martinique">America/Martinique</option>
                        <option value="America/Matamoros">America/Matamoros</option>
                        <option value="America/Mazatlan">America/Mazatlan</option>
                        <option value="America/Mendoza">America/Mendoza</option>
                        <option value="America/Menominee">America/Menominee</option>
                        <option value="America/Merida">America/Merida</option>
                        <option value="America/Metlakatla">America/Metlakatla</option>
                        <option value="America/Mexico_City">America/Mexico_City</option>
                        <option value="America/Miquelon">America/Miquelon</option>
                        <option value="America/Moncton">America/Moncton</option>
                        <option value="America/Monterrey">America/Monterrey</option>
                        <option value="America/Montevideo">America/Montevideo</option>
                        <option value="America/Montreal">America/Montreal</option>
                        <option value="America/Montserrat">America/Montserrat</option>
                        <option value="America/Nassau">America/Nassau</option>
                        <option value="America/New_York">America/New_York</option>
                        <option value="America/Nipigon">America/Nipigon</option>
                        <option value="America/Nome">America/Nome</option>
                        <option value="America/Noronha">America/Noronha</option>
                        <option value="America/North_Dakota/Beulah">America/North_Dakota/Beulah</option>
                        <option value="America/North_Dakota/Center">America/North_Dakota/Center</option>
                        <option value="America/North_Dakota/New_Salem">America/North_Dakota/New_Salem</option>
                        <option value="America/Ojinaga">America/Ojinaga</option>
                        <option value="America/Panama">America/Panama</option>
                        <option value="America/Pangnirtung">America/Pangnirtung</option>
                        <option value="America/Paramaribo">America/Paramaribo</option>
                        <option value="America/Phoenix">America/Phoenix</option>
                        <option value="America/Port-au-Prince">America/Port-au-Prince</option>
                        <option value="America/Port_of_Spain">America/Port_of_Spain</option>
                        <option value="America/Porto_Acre">America/Porto_Acre</option>
                        <option value="America/Porto_Velho">America/Porto_Velho</option>
                        <option value="America/Puerto_Rico">America/Puerto_Rico</option>
                        <option value="America/Punta_Arenas">America/Punta_Arenas</option>
                        <option value="America/Rainy_River">America/Rainy_River</option>
                        <option value="America/Rankin_Inlet">America/Rankin_Inlet</option>
                        <option value="America/Recife">America/Recife</option>
                        <option value="America/Regina">America/Regina</option>
                        <option value="America/Resolute">America/Resolute</option>
                        <option value="America/Rio_Branco">America/Rio_Branco</option>
                        <option value="America/Rosario">America/Rosario</option>
                        <option value="America/Santa_Isabel">America/Santa_Isabel</option>
                        <option value="America/Santarem">America/Santarem</option>
                        <option value="America/Santiago">America/Santiago</option>
                        <option value="America/Santo_Domingo">America/Santo_Domingo</option>
                        <option value="America/Sao_Paulo">America/Sao_Paulo</option>
                        <option value="America/Scoresbysund">America/Scoresbysund</option>
                        <option value="America/Shiprock">America/Shiprock</option>
                        <option value="America/Sitka">America/Sitka</option>
                        <option value="America/St_Barthelemy">America/St_Barthelemy</option>
                        <option value="America/St_Johns">America/St_Johns</option>
                        <option value="America/St_Kitts">America/St_Kitts</option>
                        <option value="America/St_Lucia">America/St_Lucia</option>
                        <option value="America/St_Thomas">America/St_Thomas</option>
                        <option value="America/St_Vincent">America/St_Vincent</option>
                        <option value="America/Swift_Current">America/Swift_Current</option>
                        <option value="America/Tegucigalpa">America/Tegucigalpa</option>
                        <option value="America/Thule">America/Thule</option>
                        <option value="America/Thunder_Bay">America/Thunder_Bay</option>
                        <option value="America/Tijuana">America/Tijuana</option>
                        <option value="America/Toronto">America/Toronto</option>
                        <option value="America/Tortola">America/Tortola</option>
                        <option value="America/Vancouver">America/Vancouver</option>
                        <option value="America/Virgin">America/Virgin</option>
                        <option value="America/Whitehorse">America/Whitehorse</option>
                        <option value="America/Winnipeg">America/Winnipeg</option>
                        <option value="America/Yakutat">America/Yakutat</option>
                        <option value="America/Yellowknife">America/Yellowknife</option>
                        <option value="Antarctica/Casey">Antarctica/Casey</option>
                        <option value="Antarctica/Davis">Antarctica/Davis</option>
                        <option value="Antarctica/DumontDUrville">Antarctica/DumontDUrville</option>
                        <option value="Antarctica/Macquarie">Antarctica/Macquarie</option>
                        <option value="Antarctica/Mawson">Antarctica/Mawson</option>
                        <option value="Antarctica/McMurdo">Antarctica/McMurdo</option>
                        <option value="Antarctica/Palmer">Antarctica/Palmer</option>
                        <option value="Antarctica/Rothera">Antarctica/Rothera</option>
                        <option value="Antarctica/South_Pole">Antarctica/South_Pole</option>
                        <option value="Antarctica/Syowa">Antarctica/Syowa</option>
                        <option value="Antarctica/Troll">Antarctica/Troll</option>
                        <option value="Antarctica/Vostok">Antarctica/Vostok</option>
                        <option value="Arctic/Longyearbyen">Arctic/Longyearbyen</option>
                        <option value="Asia/Aden">Asia/Aden</option>
                        <option value="Asia/Almaty">Asia/Almaty</option>
                        <option value="Asia/Amman">Asia/Amman</option>
                        <option value="Asia/Anadyr">Asia/Anadyr</option>
                        <option value="Asia/Aqtau">Asia/Aqtau</option>
                        <option value="Asia/Aqtobe">Asia/Aqtobe</option>
                        <option value="Asia/Ashgabat">Asia/Ashgabat</option>
                        <option value="Asia/Ashkhabad">Asia/Ashkhabad</option>
                        <option value="Asia/Atyrau">Asia/Atyrau</option>
                        <option value="Asia/Baghdad">Asia/Baghdad</option>
                        <option value="Asia/Bahrain">Asia/Bahrain</option>
                        <option value="Asia/Baku">Asia/Baku</option>
                        <option value="Asia/Bangkok">Asia/Bangkok</option>
                        <option value="Asia/Barnaul">Asia/Barnaul</option>
                        <option value="Asia/Beirut">Asia/Beirut</option>
                        <option value="Asia/Bishkek">Asia/Bishkek</option>
                        <option value="Asia/Brunei">Asia/Brunei</option>
                        <option value="Asia/Calcutta">Asia/Calcutta</option>
                        <option value="Asia/Chita">Asia/Chita</option>
                        <option value="Asia/Choibalsan">Asia/Choibalsan</option>
                        <option value="Asia/Chongqing">Asia/Chongqing</option>
                        <option value="Asia/Chungking">Asia/Chungking</option>
                        <option value="Asia/Colombo">Asia/Colombo</option>
                        <option value="Asia/Dacca">Asia/Dacca</option>
                        <option value="Asia/Damascus">Asia/Damascus</option>
                        <option value="Asia/Dhaka">Asia/Dhaka</option>
                        <option value="Asia/Dili">Asia/Dili</option>
                        <option value="Asia/Dubai">Asia/Dubai</option>
                        <option value="Asia/Dushanbe">Asia/Dushanbe</option>
                        <option value="Asia/Famagusta">Asia/Famagusta</option>
                        <option value="Asia/Gaza">Asia/Gaza</option>
                        <option value="Asia/Harbin">Asia/Harbin</option>
                        <option value="Asia/Hebron">Asia/Hebron</option>
                        <option value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</option>
                        <option value="Asia/Hong_Kong">Asia/Hong_Kong</option>
                        <option value="Asia/Hovd">Asia/Hovd</option>
                        <option value="Asia/Irkutsk">Asia/Irkutsk</option>
                        <option value="Asia/Istanbul">Asia/Istanbul</option>
                        <option value="Asia/Jakarta">Asia/Jakarta</option>
                        <option value="Asia/Jayapura">Asia/Jayapura</option>
                        <option value="Asia/Jerusalem">Asia/Jerusalem</option>
                        <option value="Asia/Kabul">Asia/Kabul</option>
                        <option value="Asia/Kamchatka">Asia/Kamchatka</option>
                        <option value="Asia/Karachi">Asia/Karachi</option>
                        <option value="Asia/Kashgar">Asia/Kashgar</option>
                        <option value="Asia/Kathmandu">Asia/Kathmandu</option>
                        <option value="Asia/Katmandu">Asia/Katmandu</option>
                        <option value="Asia/Khandyga">Asia/Khandyga</option>
                        <option value="Asia/Kolkata">Asia/Kolkata</option>
                        <option value="Asia/Krasnoyarsk">Asia/Krasnoyarsk</option>
                        <option value="Asia/Kuala_Lumpur">Asia/Kuala_Lumpur</option>
                        <option value="Asia/Kuching">Asia/Kuching</option>
                        <option value="Asia/Kuwait">Asia/Kuwait</option>
                        <option value="Asia/Macao">Asia/Macao</option>
                        <option value="Asia/Macau">Asia/Macau</option>
                        <option value="Asia/Magadan">Asia/Magadan</option>
                        <option value="Asia/Makassar">Asia/Makassar</option>
                        <option value="Asia/Manila">Asia/Manila</option>
                        <option value="Asia/Muscat">Asia/Muscat</option>
                        <option value="Asia/Nicosia">Asia/Nicosia</option>
                        <option value="Asia/Novokuznetsk">Asia/Novokuznetsk</option>
                        <option value="Asia/Novosibirsk">Asia/Novosibirsk</option>
                        <option value="Asia/Omsk">Asia/Omsk</option>
                        <option value="Asia/Oral">Asia/Oral</option>
                        <option value="Asia/Phnom_Penh">Asia/Phnom_Penh</option>
                        <option value="Asia/Pontianak">Asia/Pontianak</option>
                        <option value="Asia/Pyongyang">Asia/Pyongyang</option>
                        <option value="Asia/Qatar">Asia/Qatar</option>
                        <option value="Asia/Qyzylorda">Asia/Qyzylorda</option>
                        <option value="Asia/Rangoon">Asia/Rangoon</option>
                        <option value="Asia/Riyadh">Asia/Riyadh</option>
                        <option value="Asia/Saigon">Asia/Saigon</option>
                        <option value="Asia/Sakhalin">Asia/Sakhalin</option>
                        <option value="Asia/Samarkand">Asia/Samarkand</option>
                        <option value="Asia/Seoul">Asia/Seoul</option>
                        <option value="Asia/Shanghai">Asia/Shanghai</option>
                        <option value="Asia/Singapore">Asia/Singapore</option>
                        <option value="Asia/Srednekolymsk">Asia/Srednekolymsk</option>
                        <option value="Asia/Taipei">Asia/Taipei</option>
                        <option value="Asia/Tashkent">Asia/Tashkent</option>
                        <option value="Asia/Tbilisi">Asia/Tbilisi</option>
                        <option value="Asia/Tehran">Asia/Tehran</option>
                        <option value="Asia/Tel_Aviv">Asia/Tel_Aviv</option>
                        <option value="Asia/Thimbu">Asia/Thimbu</option>
                        <option value="Asia/Thimphu">Asia/Thimphu</option>
                        <option value="Asia/Tokyo">Asia/Tokyo</option>
                        <option value="Asia/Tomsk">Asia/Tomsk</option>
                        <option value="Asia/Ujung_Pandang">Asia/Ujung_Pandang</option>
                        <option value="Asia/Ulaanbaatar">Asia/Ulaanbaatar</option>
                        <option value="Asia/Ulan_Bator">Asia/Ulan_Bator</option>
                        <option value="Asia/Urumqi">Asia/Urumqi</option>
                        <option value="Asia/Ust-Nera">Asia/Ust-Nera</option>
                        <option value="Asia/Vientiane">Asia/Vientiane</option>
                        <option value="Asia/Vladivostok">Asia/Vladivostok</option>
                        <option value="Asia/Yakutsk">Asia/Yakutsk</option>
                        <option value="Asia/Yangon">Asia/Yangon</option>
                        <option value="Asia/Yekaterinburg">Asia/Yekaterinburg</option>
                        <option value="Asia/Yerevan">Asia/Yerevan</option>
                        <option value="Atlantic/Azores">Atlantic/Azores</option>
                        <option value="Atlantic/Bermuda">Atlantic/Bermuda</option>
                        <option value="Atlantic/Canary">Atlantic/Canary</option>
                        <option value="Atlantic/Cape_Verde">Atlantic/Cape_Verde</option>
                        <option value="Atlantic/Faeroe">Atlantic/Faeroe</option>
                        <option value="Atlantic/Faroe">Atlantic/Faroe</option>
                        <option value="Atlantic/Jan_Mayen">Atlantic/Jan_Mayen</option>
                        <option value="Atlantic/Madeira">Atlantic/Madeira</option>
                        <option value="Atlantic/Reykjavik">Atlantic/Reykjavik</option>
                        <option value="Atlantic/South_Georgia">Atlantic/South_Georgia</option>
                        <option value="Atlantic/St_Helena">Atlantic/St_Helena</option>
                        <option value="Atlantic/Stanley">Atlantic/Stanley</option>
                        <option value="Australia/ACT">Australia/ACT</option>
                        <option value="Australia/Adelaide">Australia/Adelaide</option>
                        <option value="Australia/Brisbane">Australia/Brisbane</option>
                        <option value="Australia/Broken_Hill">Australia/Broken_Hill</option>
                        <option value="Australia/Canberra">Australia/Canberra</option>
                        <option value="Australia/Currie">Australia/Currie</option>
                        <option value="Australia/Darwin">Australia/Darwin</option>
                        <option value="Australia/Eucla">Australia/Eucla</option>
                        <option value="Australia/Hobart">Australia/Hobart</option>
                        <option value="Australia/LHI">Australia/LHI</option>
                        <option value="Australia/Lindeman">Australia/Lindeman</option>
                        <option value="Australia/Lord_Howe">Australia/Lord_Howe</option>
                        <option value="Australia/Melbourne">Australia/Melbourne</option>
                        <option value="Australia/NSW">Australia/NSW</option>
                        <option value="Australia/North">Australia/North</option>
                        <option value="Australia/Perth">Australia/Perth</option>
                        <option value="Australia/Queensland">Australia/Queensland</option>
                        <option value="Australia/South">Australia/South</option>
                        <option value="Australia/Sydney">Australia/Sydney</option>
                        <option value="Australia/Tasmania">Australia/Tasmania</option>
                        <option value="Australia/Victoria">Australia/Victoria</option>
                        <option value="Australia/West">Australia/West</option>
                        <option value="Australia/Yancowinna">Australia/Yancowinna</option>
                        <option value="Brazil/Acre">Brazil/Acre</option>
                        <option value="Brazil/DeNoronha">Brazil/DeNoronha</option>
                        <option value="Brazil/East">Brazil/East</option>
                        <option value="Brazil/West">Brazil/West</option>
                        <option value="CET">CET</option>
                        <option value="CST6CDT">CST6CDT</option>
                        <option value="Canada/Atlantic">Canada/Atlantic</option>
                        <option value="Canada/Central">Canada/Central</option>
                        <option value="Canada/East-Saskatchewan">Canada/East-Saskatchewan</option>
                        <option value="Canada/Eastern">Canada/Eastern</option>
                        <option value="Canada/Mountain">Canada/Mountain</option>
                        <option value="Canada/Newfoundland">Canada/Newfoundland</option>
                        <option value="Canada/Pacific">Canada/Pacific</option>
                        <option value="Canada/Saskatchewan">Canada/Saskatchewan</option>
                        <option value="Canada/Yukon">Canada/Yukon</option>
                        <option value="Chile/Continental">Chile/Continental</option>
                        <option value="Chile/EasterIsland">Chile/EasterIsland</option>
                        <option value="Cuba">Cuba</option>
                        <option value="EET">EET</option>
                        <option value="EST">EST</option>
                        <option value="EST5EDT">EST5EDT</option>
                        <option value="Egypt">Egypt</option>
                        <option value="Eire">Eire</option>
                        <option value="Etc/GMT">Etc/GMT</option>
                        <option value="Etc/GMT+0">Etc/GMT+0</option>
                        <option value="Etc/GMT+1">Etc/GMT+1</option>
                        <option value="Etc/GMT+10">Etc/GMT+10</option>
                        <option value="Etc/GMT+11">Etc/GMT+11</option>
                        <option value="Etc/GMT+12">Etc/GMT+12</option>
                        <option value="Etc/GMT+2">Etc/GMT+2</option>
                        <option value="Etc/GMT+3">Etc/GMT+3</option>
                        <option value="Etc/GMT+4">Etc/GMT+4</option>
                        <option value="Etc/GMT+5">Etc/GMT+5</option>
                        <option value="Etc/GMT+6">Etc/GMT+6</option>
                        <option value="Etc/GMT+7">Etc/GMT+7</option>
                        <option value="Etc/GMT+8">Etc/GMT+8</option>
                        <option value="Etc/GMT+9">Etc/GMT+9</option>
                        <option value="Etc/GMT-0">Etc/GMT-0</option>
                        <option value="Etc/GMT-1">Etc/GMT-1</option>
                        <option value="Etc/GMT-10">Etc/GMT-10</option>
                        <option value="Etc/GMT-11">Etc/GMT-11</option>
                        <option value="Etc/GMT-12">Etc/GMT-12</option>
                        <option value="Etc/GMT-13">Etc/GMT-13</option>
                        <option value="Etc/GMT-14">Etc/GMT-14</option>
                        <option value="Etc/GMT-2">Etc/GMT-2</option>
                        <option value="Etc/GMT-3">Etc/GMT-3</option>
                        <option value="Etc/GMT-4">Etc/GMT-4</option>
                        <option value="Etc/GMT-5">Etc/GMT-5</option>
                        <option value="Etc/GMT-6">Etc/GMT-6</option>
                        <option value="Etc/GMT-7">Etc/GMT-7</option>
                        <option value="Etc/GMT-8">Etc/GMT-8</option>
                        <option value="Etc/GMT-9">Etc/GMT-9</option>
                        <option value="Etc/GMT0">Etc/GMT0</option>
                        <option value="Etc/Greenwich">Etc/Greenwich</option>
                        <option value="Etc/UCT">Etc/UCT</option>
                        <option value="Etc/UTC">Etc/UTC</option>
                        <option value="Etc/Universal">Etc/Universal</option>
                        <option value="Etc/Zulu">Etc/Zulu</option>
                        <option value="Europe/Amsterdam">Europe/Amsterdam</option>
                        <option value="Europe/Andorra">Europe/Andorra</option>
                        <option value="Europe/Astrakhan">Europe/Astrakhan</option>
                        <option value="Europe/Athens">Europe/Athens</option>
                        <option value="Europe/Belfast">Europe/Belfast</option>
                        <option value="Europe/Belgrade">Europe/Belgrade</option>
                        <option value="Europe/Berlin" selected="">Europe/Berlin</option>
                        <option value="Europe/Bratislava">Europe/Bratislava</option>
                        <option value="Europe/Brussels">Europe/Brussels</option>
                        <option value="Europe/Bucharest">Europe/Bucharest</option>
                        <option value="Europe/Budapest">Europe/Budapest</option>
                        <option value="Europe/Busingen">Europe/Busingen</option>
                        <option value="Europe/Chisinau">Europe/Chisinau</option>
                        <option value="Europe/Copenhagen">Europe/Copenhagen</option>
                        <option value="Europe/Dublin">Europe/Dublin</option>
                        <option value="Europe/Gibraltar">Europe/Gibraltar</option>
                        <option value="Europe/Guernsey">Europe/Guernsey</option>
                        <option value="Europe/Helsinki">Europe/Helsinki</option>
                        <option value="Europe/Isle_of_Man">Europe/Isle_of_Man</option>
                        <option value="Europe/Istanbul">Europe/Istanbul</option>
                        <option value="Europe/Jersey">Europe/Jersey</option>
                        <option value="Europe/Kaliningrad">Europe/Kaliningrad</option>
                        <option value="Europe/Kiev">Europe/Kiev</option>
                        <option value="Europe/Kirov">Europe/Kirov</option>
                        <option value="Europe/Lisbon">Europe/Lisbon</option>
                        <option value="Europe/Ljubljana">Europe/Ljubljana</option>
                        <option value="Europe/London">Europe/London</option>
                        <option value="Europe/Luxembourg">Europe/Luxembourg</option>
                        <option value="Europe/Madrid">Europe/Madrid</option>
                        <option value="Europe/Malta">Europe/Malta</option>
                        <option value="Europe/Mariehamn">Europe/Mariehamn</option>
                        <option value="Europe/Minsk">Europe/Minsk</option>
                        <option value="Europe/Monaco">Europe/Monaco</option>
                        <option value="Europe/Moscow">Europe/Moscow</option>
                        <option value="Europe/Nicosia">Europe/Nicosia</option>
                        <option value="Europe/Oslo">Europe/Oslo</option>
                        <option value="Europe/Paris">Europe/Paris</option>
                        <option value="Europe/Podgorica">Europe/Podgorica</option>
                        <option value="Europe/Prague">Europe/Prague</option>
                        <option value="Europe/Riga">Europe/Riga</option>
                        <option value="Europe/Rome">Europe/Rome</option>
                        <option value="Europe/Samara">Europe/Samara</option>
                        <option value="Europe/San_Marino">Europe/San_Marino</option>
                        <option value="Europe/Sarajevo">Europe/Sarajevo</option>
                        <option value="Europe/Saratov">Europe/Saratov</option>
                        <option value="Europe/Simferopol">Europe/Simferopol</option>
                        <option value="Europe/Skopje">Europe/Skopje</option>
                        <option value="Europe/Sofia">Europe/Sofia</option>
                        <option value="Europe/Stockholm">Europe/Stockholm</option>
                        <option value="Europe/Tallinn">Europe/Tallinn</option>
                        <option value="Europe/Tirane">Europe/Tirane</option>
                        <option value="Europe/Tiraspol">Europe/Tiraspol</option>
                        <option value="Europe/Ulyanovsk">Europe/Ulyanovsk</option>
                        <option value="Europe/Uzhgorod">Europe/Uzhgorod</option>
                        <option value="Europe/Vaduz">Europe/Vaduz</option>
                        <option value="Europe/Vatican">Europe/Vatican</option>
                        <option value="Europe/Vienna">Europe/Vienna</option>
                        <option value="Europe/Vilnius">Europe/Vilnius</option>
                        <option value="Europe/Volgograd">Europe/Volgograd</option>
                        <option value="Europe/Warsaw">Europe/Warsaw</option>
                        <option value="Europe/Zagreb">Europe/Zagreb</option>
                        <option value="Europe/Zaporozhye">Europe/Zaporozhye</option>
                        <option value="Europe/Zurich">Europe/Zurich</option>
                        <option value="GB">GB</option>
                        <option value="GB-Eire">GB-Eire</option>
                        <option value="GMT">GMT</option>
                        <option value="GMT+0">GMT+0</option>
                        <option value="GMT-0">GMT-0</option>
                        <option value="GMT0">GMT0</option>
                        <option value="Greenwich">Greenwich</option>
                        <option value="HST">HST</option>
                        <option value="Hongkong">Hongkong</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indian/Antananarivo">Indian/Antananarivo</option>
                        <option value="Indian/Chagos">Indian/Chagos</option>
                        <option value="Indian/Christmas">Indian/Christmas</option>
                        <option value="Indian/Cocos">Indian/Cocos</option>
                        <option value="Indian/Comoro">Indian/Comoro</option>
                        <option value="Indian/Kerguelen">Indian/Kerguelen</option>
                        <option value="Indian/Mahe">Indian/Mahe</option>
                        <option value="Indian/Maldives">Indian/Maldives</option>
                        <option value="Indian/Mauritius">Indian/Mauritius</option>
                        <option value="Indian/Mayotte">Indian/Mayotte</option>
                        <option value="Indian/Reunion">Indian/Reunion</option>
                        <option value="Iran">Iran</option>
                        <option value="Israel">Israel</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Kwajalein">Kwajalein</option>
                        <option value="Libya">Libya</option>
                        <option value="MET">MET</option>
                        <option value="MST">MST</option>
                        <option value="MST7MDT">MST7MDT</option>
                        <option value="Mexico/BajaNorte">Mexico/BajaNorte</option>
                        <option value="Mexico/BajaSur">Mexico/BajaSur</option>
                        <option value="Mexico/General">Mexico/General</option>
                        <option value="NZ">NZ</option>
                        <option value="NZ-CHAT">NZ-CHAT</option>
                        <option value="Navajo">Navajo</option>
                        <option value="PRC">PRC</option>
                        <option value="PST8PDT">PST8PDT</option>
                        <option value="Pacific/Apia">Pacific/Apia</option>
                        <option value="Pacific/Auckland">Pacific/Auckland</option>
                        <option value="Pacific/Bougainville">Pacific/Bougainville</option>
                        <option value="Pacific/Chatham">Pacific/Chatham</option>
                        <option value="Pacific/Chuuk">Pacific/Chuuk</option>
                        <option value="Pacific/Easter">Pacific/Easter</option>
                        <option value="Pacific/Efate">Pacific/Efate</option>
                        <option value="Pacific/Enderbury">Pacific/Enderbury</option>
                        <option value="Pacific/Fakaofo">Pacific/Fakaofo</option>
                        <option value="Pacific/Fiji">Pacific/Fiji</option>
                        <option value="Pacific/Funafuti">Pacific/Funafuti</option>
                        <option value="Pacific/Galapagos">Pacific/Galapagos</option>
                        <option value="Pacific/Gambier">Pacific/Gambier</option>
                        <option value="Pacific/Guadalcanal">Pacific/Guadalcanal</option>
                        <option value="Pacific/Guam">Pacific/Guam</option>
                        <option value="Pacific/Honolulu">Pacific/Honolulu</option>
                        <option value="Pacific/Johnston">Pacific/Johnston</option>
                        <option value="Pacific/Kiritimati">Pacific/Kiritimati</option>
                        <option value="Pacific/Kosrae">Pacific/Kosrae</option>
                        <option value="Pacific/Kwajalein">Pacific/Kwajalein</option>
                        <option value="Pacific/Majuro">Pacific/Majuro</option>
                        <option value="Pacific/Marquesas">Pacific/Marquesas</option>
                        <option value="Pacific/Midway">Pacific/Midway</option>
                        <option value="Pacific/Nauru">Pacific/Nauru</option>
                        <option value="Pacific/Niue">Pacific/Niue</option>
                        <option value="Pacific/Norfolk">Pacific/Norfolk</option>
                        <option value="Pacific/Noumea">Pacific/Noumea</option>
                        <option value="Pacific/Pago_Pago">Pacific/Pago_Pago</option>
                        <option value="Pacific/Palau">Pacific/Palau</option>
                        <option value="Pacific/Pitcairn">Pacific/Pitcairn</option>
                        <option value="Pacific/Pohnpei">Pacific/Pohnpei</option>
                        <option value="Pacific/Ponape">Pacific/Ponape</option>
                        <option value="Pacific/Port_Moresby">Pacific/Port_Moresby</option>
                        <option value="Pacific/Rarotonga">Pacific/Rarotonga</option>
                        <option value="Pacific/Saipan">Pacific/Saipan</option>
                        <option value="Pacific/Samoa">Pacific/Samoa</option>
                        <option value="Pacific/Tahiti">Pacific/Tahiti</option>
                        <option value="Pacific/Tarawa">Pacific/Tarawa</option>
                        <option value="Pacific/Tongatapu">Pacific/Tongatapu</option>
                        <option value="Pacific/Truk">Pacific/Truk</option>
                        <option value="Pacific/Wake">Pacific/Wake</option>
                        <option value="Pacific/Wallis">Pacific/Wallis</option>
                        <option value="Pacific/Yap">Pacific/Yap</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="ROC">ROC</option>
                        <option value="ROK">ROK</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Turkey">Turkey</option>
                        <option value="UCT">UCT</option>
                        <option value="US/Alaska">US/Alaska</option>
                        <option value="US/Aleutian">US/Aleutian</option>
                        <option value="US/Arizona">US/Arizona</option>
                        <option value="US/Central">US/Central</option>
                        <option value="US/East-Indiana">US/East-Indiana</option>
                        <option value="US/Eastern">US/Eastern</option>
                        <option value="US/Hawaii">US/Hawaii</option>
                        <option value="US/Indiana-Starke">US/Indiana-Starke</option>
                        <option value="US/Michigan">US/Michigan</option>
                        <option value="US/Mountain">US/Mountain</option>
                        <option value="US/Pacific">US/Pacific</option>
                        <option value="US/Pacific-New">US/Pacific-New</option>
                        <option value="US/Samoa">US/Samoa</option>
                        <option value="UTC">UTC</option>
                        <option value="Universal">Universal</option>
                        <option value="W-SU">W-SU</option>
                        <option value="WET">WET</option>
                        <option value="Zulu">Zulu</option>
                      </select>
                        <i class="dropdown icon"></i>
                        <input class="search" autocomplete="off" tabindex="0" />
                        <div class="text">Europe/Berlin</div>
                        <div class="menu" tabindex="-1">
                          <div class="item" data-value="Africa/Abidjan">Africa/Abidjan</div>
                          <div class="item" data-value="Africa/Accra">Africa/Accra</div>
                          <div class="item" data-value="Africa/Addis_Ababa">Africa/Addis_Ababa</div>
                          <div class="item" data-value="Africa/Algiers">Africa/Algiers</div>
                          <div class="item" data-value="Africa/Asmara">Africa/Asmara</div>
                          <div class="item" data-value="Africa/Asmera">Africa/Asmera</div>
                          <div class="item" data-value="Africa/Bamako">Africa/Bamako</div>
                          <div class="item" data-value="Africa/Bangui">Africa/Bangui</div>
                          <div class="item" data-value="Africa/Banjul">Africa/Banjul</div>
                          <div class="item" data-value="Africa/Bissau">Africa/Bissau</div>
                          <div class="item" data-value="Africa/Blantyre">Africa/Blantyre</div>
                          <div class="item" data-value="Africa/Brazzaville">Africa/Brazzaville</div>
                          <div class="item" data-value="Africa/Bujumbura">Africa/Bujumbura</div>
                          <div class="item" data-value="Africa/Cairo">Africa/Cairo</div>
                          <div class="item" data-value="Africa/Casablanca">Africa/Casablanca</div>
                          <div class="item" data-value="Africa/Ceuta">Africa/Ceuta</div>
                          <div class="item" data-value="Africa/Conakry">Africa/Conakry</div>
                          <div class="item" data-value="Africa/Dakar">Africa/Dakar</div>
                          <div class="item" data-value="Africa/Dar_es_Salaam">Africa/Dar_es_Salaam</div>
                          <div class="item" data-value="Africa/Djibouti">Africa/Djibouti</div>
                          <div class="item" data-value="Africa/Douala">Africa/Douala</div>
                          <div class="item" data-value="Africa/El_Aaiun">Africa/El_Aaiun</div>
                          <div class="item" data-value="Africa/Freetown">Africa/Freetown</div>
                          <div class="item" data-value="Africa/Gaborone">Africa/Gaborone</div>
                          <div class="item" data-value="Africa/Harare">Africa/Harare</div>
                          <div class="item" data-value="Africa/Johannesburg">Africa/Johannesburg</div>
                          <div class="item" data-value="Africa/Juba">Africa/Juba</div>
                          <div class="item" data-value="Africa/Kampala">Africa/Kampala</div>
                          <div class="item" data-value="Africa/Khartoum">Africa/Khartoum</div>
                          <div class="item" data-value="Africa/Kigali">Africa/Kigali</div>
                          <div class="item" data-value="Africa/Kinshasa">Africa/Kinshasa</div>
                          <div class="item" data-value="Africa/Lagos">Africa/Lagos</div>
                          <div class="item" data-value="Africa/Libreville">Africa/Libreville</div>
                          <div class="item" data-value="Africa/Lome">Africa/Lome</div>
                          <div class="item" data-value="Africa/Luanda">Africa/Luanda</div>
                          <div class="item" data-value="Africa/Lubumbashi">Africa/Lubumbashi</div>
                          <div class="item" data-value="Africa/Lusaka">Africa/Lusaka</div>
                          <div class="item" data-value="Africa/Malabo">Africa/Malabo</div>
                          <div class="item" data-value="Africa/Maputo">Africa/Maputo</div>
                          <div class="item" data-value="Africa/Maseru">Africa/Maseru</div>
                          <div class="item" data-value="Africa/Mbabane">Africa/Mbabane</div>
                          <div class="item" data-value="Africa/Mogadishu">Africa/Mogadishu</div>
                          <div class="item" data-value="Africa/Monrovia">Africa/Monrovia</div>
                          <div class="item" data-value="Africa/Nairobi">Africa/Nairobi</div>
                          <div class="item" data-value="Africa/Ndjamena">Africa/Ndjamena</div>
                          <div class="item" data-value="Africa/Niamey">Africa/Niamey</div>
                          <div class="item" data-value="Africa/Nouakchott">Africa/Nouakchott</div>
                          <div class="item" data-value="Africa/Ouagadougou">Africa/Ouagadougou</div>
                          <div class="item" data-value="Africa/Porto-Novo">Africa/Porto-Novo</div>
                          <div class="item" data-value="Africa/Sao_Tome">Africa/Sao_Tome</div>
                          <div class="item" data-value="Africa/Timbuktu">Africa/Timbuktu</div>
                          <div class="item" data-value="Africa/Tripoli">Africa/Tripoli</div>
                          <div class="item" data-value="Africa/Tunis">Africa/Tunis</div>
                          <div class="item" data-value="Africa/Windhoek">Africa/Windhoek</div>
                          <div class="item" data-value="America/Adak">America/Adak</div>
                          <div class="item" data-value="America/Anchorage">America/Anchorage</div>
                          <div class="item" data-value="America/Anguilla">America/Anguilla</div>
                          <div class="item" data-value="America/Antigua">America/Antigua</div>
                          <div class="item" data-value="America/Araguaina">America/Araguaina</div>
                          <div class="item" data-value="America/Argentina/Buenos_Aires">America/Argentina/Buenos_Aires</div>
                          <div class="item" data-value="America/Argentina/Catamarca">America/Argentina/Catamarca</div>
                          <div class="item" data-value="America/Argentina/ComodRivadavia">America/Argentina/ComodRivadavia</div>
                          <div class="item" data-value="America/Argentina/Cordoba">America/Argentina/Cordoba</div>
                          <div class="item" data-value="America/Argentina/Jujuy">America/Argentina/Jujuy</div>
                          <div class="item" data-value="America/Argentina/La_Rioja">America/Argentina/La_Rioja</div>
                          <div class="item" data-value="America/Argentina/Mendoza">America/Argentina/Mendoza</div>
                          <div class="item" data-value="America/Argentina/Rio_Gallegos">America/Argentina/Rio_Gallegos</div>
                          <div class="item" data-value="America/Argentina/Salta">America/Argentina/Salta</div>
                          <div class="item" data-value="America/Argentina/San_Juan">America/Argentina/San_Juan</div>
                          <div class="item" data-value="America/Argentina/San_Luis">America/Argentina/San_Luis</div>
                          <div class="item" data-value="America/Argentina/Tucuman">America/Argentina/Tucuman</div>
                          <div class="item" data-value="America/Argentina/Ushuaia">America/Argentina/Ushuaia</div>
                          <div class="item" data-value="America/Aruba">America/Aruba</div>
                          <div class="item" data-value="America/Asuncion">America/Asuncion</div>
                          <div class="item" data-value="America/Atikokan">America/Atikokan</div>
                          <div class="item" data-value="America/Atka">America/Atka</div>
                          <div class="item" data-value="America/Bahia">America/Bahia</div>
                          <div class="item" data-value="America/Bahia_Banderas">America/Bahia_Banderas</div>
                          <div class="item" data-value="America/Barbados">America/Barbados</div>
                          <div class="item" data-value="America/Belem">America/Belem</div>
                          <div class="item" data-value="America/Belize">America/Belize</div>
                          <div class="item" data-value="America/Blanc-Sablon">America/Blanc-Sablon</div>
                          <div class="item" data-value="America/Boa_Vista">America/Boa_Vista</div>
                          <div class="item" data-value="America/Bogota">America/Bogota</div>
                          <div class="item" data-value="America/Boise">America/Boise</div>
                          <div class="item" data-value="America/Buenos_Aires">America/Buenos_Aires</div>
                          <div class="item" data-value="America/Cambridge_Bay">America/Cambridge_Bay</div>
                          <div class="item" data-value="America/Campo_Grande">America/Campo_Grande</div>
                          <div class="item" data-value="America/Cancun">America/Cancun</div>
                          <div class="item" data-value="America/Caracas">America/Caracas</div>
                          <div class="item" data-value="America/Catamarca">America/Catamarca</div>
                          <div class="item" data-value="America/Cayenne">America/Cayenne</div>
                          <div class="item" data-value="America/Cayman">America/Cayman</div>
                          <div class="item" data-value="America/Chicago">America/Chicago</div>
                          <div class="item" data-value="America/Chihuahua">America/Chihuahua</div>
                          <div class="item" data-value="America/Coral_Harbour">America/Coral_Harbour</div>
                          <div class="item" data-value="America/Cordoba">America/Cordoba</div>
                          <div class="item" data-value="America/Costa_Rica">America/Costa_Rica</div>
                          <div class="item" data-value="America/Creston">America/Creston</div>
                          <div class="item" data-value="America/Cuiaba">America/Cuiaba</div>
                          <div class="item" data-value="America/Curacao">America/Curacao</div>
                          <div class="item" data-value="America/Danmarkshavn">America/Danmarkshavn</div>
                          <div class="item" data-value="America/Dawson">America/Dawson</div>
                          <div class="item" data-value="America/Dawson_Creek">America/Dawson_Creek</div>
                          <div class="item" data-value="America/Denver">America/Denver</div>
                          <div class="item" data-value="America/Detroit">America/Detroit</div>
                          <div class="item" data-value="America/Dominica">America/Dominica</div>
                          <div class="item" data-value="America/Edmonton">America/Edmonton</div>
                          <div class="item" data-value="America/Eirunepe">America/Eirunepe</div>
                          <div class="item" data-value="America/El_Salvador">America/El_Salvador</div>
                          <div class="item" data-value="America/Ensenada">America/Ensenada</div>
                          <div class="item" data-value="America/Fort_Nelson">America/Fort_Nelson</div>
                          <div class="item" data-value="America/Fort_Wayne">America/Fort_Wayne</div>
                          <div class="item" data-value="America/Fortaleza">America/Fortaleza</div>
                          <div class="item" data-value="America/Glace_Bay">America/Glace_Bay</div>
                          <div class="item" data-value="America/Godthab">America/Godthab</div>
                          <div class="item" data-value="America/Goose_Bay">America/Goose_Bay</div>
                          <div class="item" data-value="America/Grand_Turk">America/Grand_Turk</div>
                          <div class="item" data-value="America/Grenada">America/Grenada</div>
                          <div class="item" data-value="America/Guadeloupe">America/Guadeloupe</div>
                          <div class="item" data-value="America/Guatemala">America/Guatemala</div>
                          <div class="item" data-value="America/Guayaquil">America/Guayaquil</div>
                          <div class="item" data-value="America/Guyana">America/Guyana</div>
                          <div class="item" data-value="America/Halifax">America/Halifax</div>
                          <div class="item" data-value="America/Havana">America/Havana</div>
                          <div class="item" data-value="America/Hermosillo">America/Hermosillo</div>
                          <div class="item" data-value="America/Indiana/Indianapolis">America/Indiana/Indianapolis</div>
                          <div class="item" data-value="America/Indiana/Knox">America/Indiana/Knox</div>
                          <div class="item" data-value="America/Indiana/Marengo">America/Indiana/Marengo</div>
                          <div class="item" data-value="America/Indiana/Petersburg">America/Indiana/Petersburg</div>
                          <div class="item" data-value="America/Indiana/Tell_City">America/Indiana/Tell_City</div>
                          <div class="item" data-value="America/Indiana/Vevay">America/Indiana/Vevay</div>
                          <div class="item" data-value="America/Indiana/Vincennes">America/Indiana/Vincennes</div>
                          <div class="item" data-value="America/Indiana/Winamac">America/Indiana/Winamac</div>
                          <div class="item" data-value="America/Indianapolis">America/Indianapolis</div>
                          <div class="item" data-value="America/Inuvik">America/Inuvik</div>
                          <div class="item" data-value="America/Iqaluit">America/Iqaluit</div>
                          <div class="item" data-value="America/Jamaica">America/Jamaica</div>
                          <div class="item" data-value="America/Jujuy">America/Jujuy</div>
                          <div class="item" data-value="America/Juneau">America/Juneau</div>
                          <div class="item" data-value="America/Kentucky/Louisville">America/Kentucky/Louisville</div>
                          <div class="item" data-value="America/Kentucky/Monticello">America/Kentucky/Monticello</div>
                          <div class="item" data-value="America/Knox_IN">America/Knox_IN</div>
                          <div class="item" data-value="America/Kralendijk">America/Kralendijk</div>
                          <div class="item" data-value="America/La_Paz">America/La_Paz</div>
                          <div class="item" data-value="America/Lima">America/Lima</div>
                          <div class="item" data-value="America/Los_Angeles">America/Los_Angeles</div>
                          <div class="item" data-value="America/Louisville">America/Louisville</div>
                          <div class="item" data-value="America/Lower_Princes">America/Lower_Princes</div>
                          <div class="item" data-value="America/Maceio">America/Maceio</div>
                          <div class="item" data-value="America/Managua">America/Managua</div>
                          <div class="item" data-value="America/Manaus">America/Manaus</div>
                          <div class="item" data-value="America/Marigot">America/Marigot</div>
                          <div class="item" data-value="America/Martinique">America/Martinique</div>
                          <div class="item" data-value="America/Matamoros">America/Matamoros</div>
                          <div class="item" data-value="America/Mazatlan">America/Mazatlan</div>
                          <div class="item" data-value="America/Mendoza">America/Mendoza</div>
                          <div class="item" data-value="America/Menominee">America/Menominee</div>
                          <div class="item" data-value="America/Merida">America/Merida</div>
                          <div class="item" data-value="America/Metlakatla">America/Metlakatla</div>
                          <div class="item" data-value="America/Mexico_City">America/Mexico_City</div>
                          <div class="item" data-value="America/Miquelon">America/Miquelon</div>
                          <div class="item" data-value="America/Moncton">America/Moncton</div>
                          <div class="item" data-value="America/Monterrey">America/Monterrey</div>
                          <div class="item" data-value="America/Montevideo">America/Montevideo</div>
                          <div class="item" data-value="America/Montreal">America/Montreal</div>
                          <div class="item" data-value="America/Montserrat">America/Montserrat</div>
                          <div class="item" data-value="America/Nassau">America/Nassau</div>
                          <div class="item" data-value="America/New_York">America/New_York</div>
                          <div class="item" data-value="America/Nipigon">America/Nipigon</div>
                          <div class="item" data-value="America/Nome">America/Nome</div>
                          <div class="item" data-value="America/Noronha">America/Noronha</div>
                          <div class="item" data-value="America/North_Dakota/Beulah">America/North_Dakota/Beulah</div>
                          <div class="item" data-value="America/North_Dakota/Center">America/North_Dakota/Center</div>
                          <div class="item" data-value="America/North_Dakota/New_Salem">America/North_Dakota/New_Salem</div>
                          <div class="item" data-value="America/Ojinaga">America/Ojinaga</div>
                          <div class="item" data-value="America/Panama">America/Panama</div>
                          <div class="item" data-value="America/Pangnirtung">America/Pangnirtung</div>
                          <div class="item" data-value="America/Paramaribo">America/Paramaribo</div>
                          <div class="item" data-value="America/Phoenix">America/Phoenix</div>
                          <div class="item" data-value="America/Port-au-Prince">America/Port-au-Prince</div>
                          <div class="item" data-value="America/Port_of_Spain">America/Port_of_Spain</div>
                          <div class="item" data-value="America/Porto_Acre">America/Porto_Acre</div>
                          <div class="item" data-value="America/Porto_Velho">America/Porto_Velho</div>
                          <div class="item" data-value="America/Puerto_Rico">America/Puerto_Rico</div>
                          <div class="item" data-value="America/Punta_Arenas">America/Punta_Arenas</div>
                          <div class="item" data-value="America/Rainy_River">America/Rainy_River</div>
                          <div class="item" data-value="America/Rankin_Inlet">America/Rankin_Inlet</div>
                          <div class="item" data-value="America/Recife">America/Recife</div>
                          <div class="item" data-value="America/Regina">America/Regina</div>
                          <div class="item" data-value="America/Resolute">America/Resolute</div>
                          <div class="item" data-value="America/Rio_Branco">America/Rio_Branco</div>
                          <div class="item" data-value="America/Rosario">America/Rosario</div>
                          <div class="item" data-value="America/Santa_Isabel">America/Santa_Isabel</div>
                          <div class="item" data-value="America/Santarem">America/Santarem</div>
                          <div class="item" data-value="America/Santiago">America/Santiago</div>
                          <div class="item" data-value="America/Santo_Domingo">America/Santo_Domingo</div>
                          <div class="item" data-value="America/Sao_Paulo">America/Sao_Paulo</div>
                          <div class="item" data-value="America/Scoresbysund">America/Scoresbysund</div>
                          <div class="item" data-value="America/Shiprock">America/Shiprock</div>
                          <div class="item" data-value="America/Sitka">America/Sitka</div>
                          <div class="item" data-value="America/St_Barthelemy">America/St_Barthelemy</div>
                          <div class="item" data-value="America/St_Johns">America/St_Johns</div>
                          <div class="item" data-value="America/St_Kitts">America/St_Kitts</div>
                          <div class="item" data-value="America/St_Lucia">America/St_Lucia</div>
                          <div class="item" data-value="America/St_Thomas">America/St_Thomas</div>
                          <div class="item" data-value="America/St_Vincent">America/St_Vincent</div>
                          <div class="item" data-value="America/Swift_Current">America/Swift_Current</div>
                          <div class="item" data-value="America/Tegucigalpa">America/Tegucigalpa</div>
                          <div class="item" data-value="America/Thule">America/Thule</div>
                          <div class="item" data-value="America/Thunder_Bay">America/Thunder_Bay</div>
                          <div class="item" data-value="America/Tijuana">America/Tijuana</div>
                          <div class="item" data-value="America/Toronto">America/Toronto</div>
                          <div class="item" data-value="America/Tortola">America/Tortola</div>
                          <div class="item" data-value="America/Vancouver">America/Vancouver</div>
                          <div class="item" data-value="America/Virgin">America/Virgin</div>
                          <div class="item" data-value="America/Whitehorse">America/Whitehorse</div>
                          <div class="item" data-value="America/Winnipeg">America/Winnipeg</div>
                          <div class="item" data-value="America/Yakutat">America/Yakutat</div>
                          <div class="item" data-value="America/Yellowknife">America/Yellowknife</div>
                          <div class="item" data-value="Antarctica/Casey">Antarctica/Casey</div>
                          <div class="item" data-value="Antarctica/Davis">Antarctica/Davis</div>
                          <div class="item" data-value="Antarctica/DumontDUrville">Antarctica/DumontDUrville</div>
                          <div class="item" data-value="Antarctica/Macquarie">Antarctica/Macquarie</div>
                          <div class="item" data-value="Antarctica/Mawson">Antarctica/Mawson</div>
                          <div class="item" data-value="Antarctica/McMurdo">Antarctica/McMurdo</div>
                          <div class="item" data-value="Antarctica/Palmer">Antarctica/Palmer</div>
                          <div class="item" data-value="Antarctica/Rothera">Antarctica/Rothera</div>
                          <div class="item" data-value="Antarctica/South_Pole">Antarctica/South_Pole</div>
                          <div class="item" data-value="Antarctica/Syowa">Antarctica/Syowa</div>
                          <div class="item" data-value="Antarctica/Troll">Antarctica/Troll</div>
                          <div class="item" data-value="Antarctica/Vostok">Antarctica/Vostok</div>
                          <div class="item" data-value="Arctic/Longyearbyen">Arctic/Longyearbyen</div>
                          <div class="item" data-value="Asia/Aden">Asia/Aden</div>
                          <div class="item" data-value="Asia/Almaty">Asia/Almaty</div>
                          <div class="item" data-value="Asia/Amman">Asia/Amman</div>
                          <div class="item" data-value="Asia/Anadyr">Asia/Anadyr</div>
                          <div class="item" data-value="Asia/Aqtau">Asia/Aqtau</div>
                          <div class="item" data-value="Asia/Aqtobe">Asia/Aqtobe</div>
                          <div class="item" data-value="Asia/Ashgabat">Asia/Ashgabat</div>
                          <div class="item" data-value="Asia/Ashkhabad">Asia/Ashkhabad</div>
                          <div class="item" data-value="Asia/Atyrau">Asia/Atyrau</div>
                          <div class="item" data-value="Asia/Baghdad">Asia/Baghdad</div>
                          <div class="item" data-value="Asia/Bahrain">Asia/Bahrain</div>
                          <div class="item" data-value="Asia/Baku">Asia/Baku</div>
                          <div class="item" data-value="Asia/Bangkok">Asia/Bangkok</div>
                          <div class="item" data-value="Asia/Barnaul">Asia/Barnaul</div>
                          <div class="item" data-value="Asia/Beirut">Asia/Beirut</div>
                          <div class="item" data-value="Asia/Bishkek">Asia/Bishkek</div>
                          <div class="item" data-value="Asia/Brunei">Asia/Brunei</div>
                          <div class="item" data-value="Asia/Calcutta">Asia/Calcutta</div>
                          <div class="item" data-value="Asia/Chita">Asia/Chita</div>
                          <div class="item" data-value="Asia/Choibalsan">Asia/Choibalsan</div>
                          <div class="item" data-value="Asia/Chongqing">Asia/Chongqing</div>
                          <div class="item" data-value="Asia/Chungking">Asia/Chungking</div>
                          <div class="item" data-value="Asia/Colombo">Asia/Colombo</div>
                          <div class="item" data-value="Asia/Dacca">Asia/Dacca</div>
                          <div class="item" data-value="Asia/Damascus">Asia/Damascus</div>
                          <div class="item" data-value="Asia/Dhaka">Asia/Dhaka</div>
                          <div class="item" data-value="Asia/Dili">Asia/Dili</div>
                          <div class="item" data-value="Asia/Dubai">Asia/Dubai</div>
                          <div class="item" data-value="Asia/Dushanbe">Asia/Dushanbe</div>
                          <div class="item" data-value="Asia/Famagusta">Asia/Famagusta</div>
                          <div class="item" data-value="Asia/Gaza">Asia/Gaza</div>
                          <div class="item" data-value="Asia/Harbin">Asia/Harbin</div>
                          <div class="item" data-value="Asia/Hebron">Asia/Hebron</div>
                          <div class="item" data-value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</div>
                          <div class="item" data-value="Asia/Hong_Kong">Asia/Hong_Kong</div>
                          <div class="item" data-value="Asia/Hovd">Asia/Hovd</div>
                          <div class="item" data-value="Asia/Irkutsk">Asia/Irkutsk</div>
                          <div class="item" data-value="Asia/Istanbul">Asia/Istanbul</div>
                          <div class="item" data-value="Asia/Jakarta">Asia/Jakarta</div>
                          <div class="item" data-value="Asia/Jayapura">Asia/Jayapura</div>
                          <div class="item" data-value="Asia/Jerusalem">Asia/Jerusalem</div>
                          <div class="item" data-value="Asia/Kabul">Asia/Kabul</div>
                          <div class="item" data-value="Asia/Kamchatka">Asia/Kamchatka</div>
                          <div class="item" data-value="Asia/Karachi">Asia/Karachi</div>
                          <div class="item" data-value="Asia/Kashgar">Asia/Kashgar</div>
                          <div class="item" data-value="Asia/Kathmandu">Asia/Kathmandu</div>
                          <div class="item" data-value="Asia/Katmandu">Asia/Katmandu</div>
                          <div class="item" data-value="Asia/Khandyga">Asia/Khandyga</div>
                          <div class="item" data-value="Asia/Kolkata">Asia/Kolkata</div>
                          <div class="item" data-value="Asia/Krasnoyarsk">Asia/Krasnoyarsk</div>
                          <div class="item" data-value="Asia/Kuala_Lumpur">Asia/Kuala_Lumpur</div>
                          <div class="item" data-value="Asia/Kuching">Asia/Kuching</div>
                          <div class="item" data-value="Asia/Kuwait">Asia/Kuwait</div>
                          <div class="item" data-value="Asia/Macao">Asia/Macao</div>
                          <div class="item" data-value="Asia/Macau">Asia/Macau</div>
                          <div class="item" data-value="Asia/Magadan">Asia/Magadan</div>
                          <div class="item" data-value="Asia/Makassar">Asia/Makassar</div>
                          <div class="item" data-value="Asia/Manila">Asia/Manila</div>
                          <div class="item" data-value="Asia/Muscat">Asia/Muscat</div>
                          <div class="item" data-value="Asia/Nicosia">Asia/Nicosia</div>
                          <div class="item" data-value="Asia/Novokuznetsk">Asia/Novokuznetsk</div>
                          <div class="item" data-value="Asia/Novosibirsk">Asia/Novosibirsk</div>
                          <div class="item" data-value="Asia/Omsk">Asia/Omsk</div>
                          <div class="item" data-value="Asia/Oral">Asia/Oral</div>
                          <div class="item" data-value="Asia/Phnom_Penh">Asia/Phnom_Penh</div>
                          <div class="item" data-value="Asia/Pontianak">Asia/Pontianak</div>
                          <div class="item" data-value="Asia/Pyongyang">Asia/Pyongyang</div>
                          <div class="item" data-value="Asia/Qatar">Asia/Qatar</div>
                          <div class="item" data-value="Asia/Qyzylorda">Asia/Qyzylorda</div>
                          <div class="item" data-value="Asia/Rangoon">Asia/Rangoon</div>
                          <div class="item" data-value="Asia/Riyadh">Asia/Riyadh</div>
                          <div class="item" data-value="Asia/Saigon">Asia/Saigon</div>
                          <div class="item" data-value="Asia/Sakhalin">Asia/Sakhalin</div>
                          <div class="item" data-value="Asia/Samarkand">Asia/Samarkand</div>
                          <div class="item" data-value="Asia/Seoul">Asia/Seoul</div>
                          <div class="item" data-value="Asia/Shanghai">Asia/Shanghai</div>
                          <div class="item" data-value="Asia/Singapore">Asia/Singapore</div>
                          <div class="item" data-value="Asia/Srednekolymsk">Asia/Srednekolymsk</div>
                          <div class="item" data-value="Asia/Taipei">Asia/Taipei</div>
                          <div class="item" data-value="Asia/Tashkent">Asia/Tashkent</div>
                          <div class="item" data-value="Asia/Tbilisi">Asia/Tbilisi</div>
                          <div class="item" data-value="Asia/Tehran">Asia/Tehran</div>
                          <div class="item" data-value="Asia/Tel_Aviv">Asia/Tel_Aviv</div>
                          <div class="item" data-value="Asia/Thimbu">Asia/Thimbu</div>
                          <div class="item" data-value="Asia/Thimphu">Asia/Thimphu</div>
                          <div class="item" data-value="Asia/Tokyo">Asia/Tokyo</div>
                          <div class="item" data-value="Asia/Tomsk">Asia/Tomsk</div>
                          <div class="item" data-value="Asia/Ujung_Pandang">Asia/Ujung_Pandang</div>
                          <div class="item" data-value="Asia/Ulaanbaatar">Asia/Ulaanbaatar</div>
                          <div class="item" data-value="Asia/Ulan_Bator">Asia/Ulan_Bator</div>
                          <div class="item" data-value="Asia/Urumqi">Asia/Urumqi</div>
                          <div class="item" data-value="Asia/Ust-Nera">Asia/Ust-Nera</div>
                          <div class="item" data-value="Asia/Vientiane">Asia/Vientiane</div>
                          <div class="item" data-value="Asia/Vladivostok">Asia/Vladivostok</div>
                          <div class="item" data-value="Asia/Yakutsk">Asia/Yakutsk</div>
                          <div class="item" data-value="Asia/Yangon">Asia/Yangon</div>
                          <div class="item" data-value="Asia/Yekaterinburg">Asia/Yekaterinburg</div>
                          <div class="item" data-value="Asia/Yerevan">Asia/Yerevan</div>
                          <div class="item" data-value="Atlantic/Azores">Atlantic/Azores</div>
                          <div class="item" data-value="Atlantic/Bermuda">Atlantic/Bermuda</div>
                          <div class="item" data-value="Atlantic/Canary">Atlantic/Canary</div>
                          <div class="item" data-value="Atlantic/Cape_Verde">Atlantic/Cape_Verde</div>
                          <div class="item" data-value="Atlantic/Faeroe">Atlantic/Faeroe</div>
                          <div class="item" data-value="Atlantic/Faroe">Atlantic/Faroe</div>
                          <div class="item" data-value="Atlantic/Jan_Mayen">Atlantic/Jan_Mayen</div>
                          <div class="item" data-value="Atlantic/Madeira">Atlantic/Madeira</div>
                          <div class="item" data-value="Atlantic/Reykjavik">Atlantic/Reykjavik</div>
                          <div class="item" data-value="Atlantic/South_Georgia">Atlantic/South_Georgia</div>
                          <div class="item" data-value="Atlantic/St_Helena">Atlantic/St_Helena</div>
                          <div class="item" data-value="Atlantic/Stanley">Atlantic/Stanley</div>
                          <div class="item" data-value="Australia/ACT">Australia/ACT</div>
                          <div class="item" data-value="Australia/Adelaide">Australia/Adelaide</div>
                          <div class="item" data-value="Australia/Brisbane">Australia/Brisbane</div>
                          <div class="item" data-value="Australia/Broken_Hill">Australia/Broken_Hill</div>
                          <div class="item" data-value="Australia/Canberra">Australia/Canberra</div>
                          <div class="item" data-value="Australia/Currie">Australia/Currie</div>
                          <div class="item" data-value="Australia/Darwin">Australia/Darwin</div>
                          <div class="item" data-value="Australia/Eucla">Australia/Eucla</div>
                          <div class="item" data-value="Australia/Hobart">Australia/Hobart</div>
                          <div class="item" data-value="Australia/LHI">Australia/LHI</div>
                          <div class="item" data-value="Australia/Lindeman">Australia/Lindeman</div>
                          <div class="item" data-value="Australia/Lord_Howe">Australia/Lord_Howe</div>
                          <div class="item" data-value="Australia/Melbourne">Australia/Melbourne</div>
                          <div class="item" data-value="Australia/NSW">Australia/NSW</div>
                          <div class="item" data-value="Australia/North">Australia/North</div>
                          <div class="item" data-value="Australia/Perth">Australia/Perth</div>
                          <div class="item" data-value="Australia/Queensland">Australia/Queensland</div>
                          <div class="item" data-value="Australia/South">Australia/South</div>
                          <div class="item" data-value="Australia/Sydney">Australia/Sydney</div>
                          <div class="item" data-value="Australia/Tasmania">Australia/Tasmania</div>
                          <div class="item" data-value="Australia/Victoria">Australia/Victoria</div>
                          <div class="item" data-value="Australia/West">Australia/West</div>
                          <div class="item" data-value="Australia/Yancowinna">Australia/Yancowinna</div>
                          <div class="item" data-value="Brazil/Acre">Brazil/Acre</div>
                          <div class="item" data-value="Brazil/DeNoronha">Brazil/DeNoronha</div>
                          <div class="item" data-value="Brazil/East">Brazil/East</div>
                          <div class="item" data-value="Brazil/West">Brazil/West</div>
                          <div class="item" data-value="CET">CET</div>
                          <div class="item" data-value="CST6CDT">CST6CDT</div>
                          <div class="item" data-value="Canada/Atlantic">Canada/Atlantic</div>
                          <div class="item" data-value="Canada/Central">Canada/Central</div>
                          <div class="item" data-value="Canada/East-Saskatchewan">Canada/East-Saskatchewan</div>
                          <div class="item" data-value="Canada/Eastern">Canada/Eastern</div>
                          <div class="item" data-value="Canada/Mountain">Canada/Mountain</div>
                          <div class="item" data-value="Canada/Newfoundland">Canada/Newfoundland</div>
                          <div class="item" data-value="Canada/Pacific">Canada/Pacific</div>
                          <div class="item" data-value="Canada/Saskatchewan">Canada/Saskatchewan</div>
                          <div class="item" data-value="Canada/Yukon">Canada/Yukon</div>
                          <div class="item" data-value="Chile/Continental">Chile/Continental</div>
                          <div class="item" data-value="Chile/EasterIsland">Chile/EasterIsland</div>
                          <div class="item" data-value="Cuba">Cuba</div>
                          <div class="item" data-value="EET">EET</div>
                          <div class="item" data-value="EST">EST</div>
                          <div class="item" data-value="EST5EDT">EST5EDT</div>
                          <div class="item" data-value="Egypt">Egypt</div>
                          <div class="item" data-value="Eire">Eire</div>
                          <div class="item" data-value="Etc/GMT">Etc/GMT</div>
                          <div class="item" data-value="Etc/GMT+0">Etc/GMT+0</div>
                          <div class="item" data-value="Etc/GMT+1">Etc/GMT+1</div>
                          <div class="item" data-value="Etc/GMT+10">Etc/GMT+10</div>
                          <div class="item" data-value="Etc/GMT+11">Etc/GMT+11</div>
                          <div class="item" data-value="Etc/GMT+12">Etc/GMT+12</div>
                          <div class="item" data-value="Etc/GMT+2">Etc/GMT+2</div>
                          <div class="item" data-value="Etc/GMT+3">Etc/GMT+3</div>
                          <div class="item" data-value="Etc/GMT+4">Etc/GMT+4</div>
                          <div class="item" data-value="Etc/GMT+5">Etc/GMT+5</div>
                          <div class="item" data-value="Etc/GMT+6">Etc/GMT+6</div>
                          <div class="item" data-value="Etc/GMT+7">Etc/GMT+7</div>
                          <div class="item" data-value="Etc/GMT+8">Etc/GMT+8</div>
                          <div class="item" data-value="Etc/GMT+9">Etc/GMT+9</div>
                          <div class="item" data-value="Etc/GMT-0">Etc/GMT-0</div>
                          <div class="item" data-value="Etc/GMT-1">Etc/GMT-1</div>
                          <div class="item" data-value="Etc/GMT-10">Etc/GMT-10</div>
                          <div class="item" data-value="Etc/GMT-11">Etc/GMT-11</div>
                          <div class="item" data-value="Etc/GMT-12">Etc/GMT-12</div>
                          <div class="item" data-value="Etc/GMT-13">Etc/GMT-13</div>
                          <div class="item" data-value="Etc/GMT-14">Etc/GMT-14</div>
                          <div class="item" data-value="Etc/GMT-2">Etc/GMT-2</div>
                          <div class="item" data-value="Etc/GMT-3">Etc/GMT-3</div>
                          <div class="item" data-value="Etc/GMT-4">Etc/GMT-4</div>
                          <div class="item" data-value="Etc/GMT-5">Etc/GMT-5</div>
                          <div class="item" data-value="Etc/GMT-6">Etc/GMT-6</div>
                          <div class="item" data-value="Etc/GMT-7">Etc/GMT-7</div>
                          <div class="item" data-value="Etc/GMT-8">Etc/GMT-8</div>
                          <div class="item" data-value="Etc/GMT-9">Etc/GMT-9</div>
                          <div class="item" data-value="Etc/GMT0">Etc/GMT0</div>
                          <div class="item" data-value="Etc/Greenwich">Etc/Greenwich</div>
                          <div class="item" data-value="Etc/UCT">Etc/UCT</div>
                          <div class="item" data-value="Etc/UTC">Etc/UTC</div>
                          <div class="item" data-value="Etc/Universal">Etc/Universal</div>
                          <div class="item" data-value="Etc/Zulu">Etc/Zulu</div>
                          <div class="item" data-value="Europe/Amsterdam">Europe/Amsterdam</div>
                          <div class="item" data-value="Europe/Andorra">Europe/Andorra</div>
                          <div class="item" data-value="Europe/Astrakhan">Europe/Astrakhan</div>
                          <div class="item" data-value="Europe/Athens">Europe/Athens</div>
                          <div class="item" data-value="Europe/Belfast">Europe/Belfast</div>
                          <div class="item" data-value="Europe/Belgrade">Europe/Belgrade</div>
                          <div class="item active selected" data-value="Europe/Berlin">Europe/Berlin</div>
                          <div class="item" data-value="Europe/Bratislava">Europe/Bratislava</div>
                          <div class="item" data-value="Europe/Brussels">Europe/Brussels</div>
                          <div class="item" data-value="Europe/Bucharest">Europe/Bucharest</div>
                          <div class="item" data-value="Europe/Budapest">Europe/Budapest</div>
                          <div class="item" data-value="Europe/Busingen">Europe/Busingen</div>
                          <div class="item" data-value="Europe/Chisinau">Europe/Chisinau</div>
                          <div class="item" data-value="Europe/Copenhagen">Europe/Copenhagen</div>
                          <div class="item" data-value="Europe/Dublin">Europe/Dublin</div>
                          <div class="item" data-value="Europe/Gibraltar">Europe/Gibraltar</div>
                          <div class="item" data-value="Europe/Guernsey">Europe/Guernsey</div>
                          <div class="item" data-value="Europe/Helsinki">Europe/Helsinki</div>
                          <div class="item" data-value="Europe/Isle_of_Man">Europe/Isle_of_Man</div>
                          <div class="item" data-value="Europe/Istanbul">Europe/Istanbul</div>
                          <div class="item" data-value="Europe/Jersey">Europe/Jersey</div>
                          <div class="item" data-value="Europe/Kaliningrad">Europe/Kaliningrad</div>
                          <div class="item" data-value="Europe/Kiev">Europe/Kiev</div>
                          <div class="item" data-value="Europe/Kirov">Europe/Kirov</div>
                          <div class="item" data-value="Europe/Lisbon">Europe/Lisbon</div>
                          <div class="item" data-value="Europe/Ljubljana">Europe/Ljubljana</div>
                          <div class="item" data-value="Europe/London">Europe/London</div>
                          <div class="item" data-value="Europe/Luxembourg">Europe/Luxembourg</div>
                          <div class="item" data-value="Europe/Madrid">Europe/Madrid</div>
                          <div class="item" data-value="Europe/Malta">Europe/Malta</div>
                          <div class="item" data-value="Europe/Mariehamn">Europe/Mariehamn</div>
                          <div class="item" data-value="Europe/Minsk">Europe/Minsk</div>
                          <div class="item" data-value="Europe/Monaco">Europe/Monaco</div>
                          <div class="item" data-value="Europe/Moscow">Europe/Moscow</div>
                          <div class="item" data-value="Europe/Nicosia">Europe/Nicosia</div>
                          <div class="item" data-value="Europe/Oslo">Europe/Oslo</div>
                          <div class="item" data-value="Europe/Paris">Europe/Paris</div>
                          <div class="item" data-value="Europe/Podgorica">Europe/Podgorica</div>
                          <div class="item" data-value="Europe/Prague">Europe/Prague</div>
                          <div class="item" data-value="Europe/Riga">Europe/Riga</div>
                          <div class="item" data-value="Europe/Rome">Europe/Rome</div>
                          <div class="item" data-value="Europe/Samara">Europe/Samara</div>
                          <div class="item" data-value="Europe/San_Marino">Europe/San_Marino</div>
                          <div class="item" data-value="Europe/Sarajevo">Europe/Sarajevo</div>
                          <div class="item" data-value="Europe/Saratov">Europe/Saratov</div>
                          <div class="item" data-value="Europe/Simferopol">Europe/Simferopol</div>
                          <div class="item" data-value="Europe/Skopje">Europe/Skopje</div>
                          <div class="item" data-value="Europe/Sofia">Europe/Sofia</div>
                          <div class="item" data-value="Europe/Stockholm">Europe/Stockholm</div>
                          <div class="item" data-value="Europe/Tallinn">Europe/Tallinn</div>
                          <div class="item" data-value="Europe/Tirane">Europe/Tirane</div>
                          <div class="item" data-value="Europe/Tiraspol">Europe/Tiraspol</div>
                          <div class="item" data-value="Europe/Ulyanovsk">Europe/Ulyanovsk</div>
                          <div class="item" data-value="Europe/Uzhgorod">Europe/Uzhgorod</div>
                          <div class="item" data-value="Europe/Vaduz">Europe/Vaduz</div>
                          <div class="item" data-value="Europe/Vatican">Europe/Vatican</div>
                          <div class="item" data-value="Europe/Vienna">Europe/Vienna</div>
                          <div class="item" data-value="Europe/Vilnius">Europe/Vilnius</div>
                          <div class="item" data-value="Europe/Volgograd">Europe/Volgograd</div>
                          <div class="item" data-value="Europe/Warsaw">Europe/Warsaw</div>
                          <div class="item" data-value="Europe/Zagreb">Europe/Zagreb</div>
                          <div class="item" data-value="Europe/Zaporozhye">Europe/Zaporozhye</div>
                          <div class="item" data-value="Europe/Zurich">Europe/Zurich</div>
                          <div class="item" data-value="GB">GB</div>
                          <div class="item" data-value="GB-Eire">GB-Eire</div>
                          <div class="item" data-value="GMT">GMT</div>
                          <div class="item" data-value="GMT+0">GMT+0</div>
                          <div class="item" data-value="GMT-0">GMT-0</div>
                          <div class="item" data-value="GMT0">GMT0</div>
                          <div class="item" data-value="Greenwich">Greenwich</div>
                          <div class="item" data-value="HST">HST</div>
                          <div class="item" data-value="Hongkong">Hongkong</div>
                          <div class="item" data-value="Iceland">Iceland</div>
                          <div class="item" data-value="Indian/Antananarivo">Indian/Antananarivo</div>
                          <div class="item" data-value="Indian/Chagos">Indian/Chagos</div>
                          <div class="item" data-value="Indian/Christmas">Indian/Christmas</div>
                          <div class="item" data-value="Indian/Cocos">Indian/Cocos</div>
                          <div class="item" data-value="Indian/Comoro">Indian/Comoro</div>
                          <div class="item" data-value="Indian/Kerguelen">Indian/Kerguelen</div>
                          <div class="item" data-value="Indian/Mahe">Indian/Mahe</div>
                          <div class="item" data-value="Indian/Maldives">Indian/Maldives</div>
                          <div class="item" data-value="Indian/Mauritius">Indian/Mauritius</div>
                          <div class="item" data-value="Indian/Mayotte">Indian/Mayotte</div>
                          <div class="item" data-value="Indian/Reunion">Indian/Reunion</div>
                          <div class="item" data-value="Iran">Iran</div>
                          <div class="item" data-value="Israel">Israel</div>
                          <div class="item" data-value="Jamaica">Jamaica</div>
                          <div class="item" data-value="Japan">Japan</div>
                          <div class="item" data-value="Kwajalein">Kwajalein</div>
                          <div class="item" data-value="Libya">Libya</div>
                          <div class="item" data-value="MET">MET</div>
                          <div class="item" data-value="MST">MST</div>
                          <div class="item" data-value="MST7MDT">MST7MDT</div>
                          <div class="item" data-value="Mexico/BajaNorte">Mexico/BajaNorte</div>
                          <div class="item" data-value="Mexico/BajaSur">Mexico/BajaSur</div>
                          <div class="item" data-value="Mexico/General">Mexico/General</div>
                          <div class="item" data-value="NZ">NZ</div>
                          <div class="item" data-value="NZ-CHAT">NZ-CHAT</div>
                          <div class="item" data-value="Navajo">Navajo</div>
                          <div class="item" data-value="PRC">PRC</div>
                          <div class="item" data-value="PST8PDT">PST8PDT</div>
                          <div class="item" data-value="Pacific/Apia">Pacific/Apia</div>
                          <div class="item" data-value="Pacific/Auckland">Pacific/Auckland</div>
                          <div class="item" data-value="Pacific/Bougainville">Pacific/Bougainville</div>
                          <div class="item" data-value="Pacific/Chatham">Pacific/Chatham</div>
                          <div class="item" data-value="Pacific/Chuuk">Pacific/Chuuk</div>
                          <div class="item" data-value="Pacific/Easter">Pacific/Easter</div>
                          <div class="item" data-value="Pacific/Efate">Pacific/Efate</div>
                          <div class="item" data-value="Pacific/Enderbury">Pacific/Enderbury</div>
                          <div class="item" data-value="Pacific/Fakaofo">Pacific/Fakaofo</div>
                          <div class="item" data-value="Pacific/Fiji">Pacific/Fiji</div>
                          <div class="item" data-value="Pacific/Funafuti">Pacific/Funafuti</div>
                          <div class="item" data-value="Pacific/Galapagos">Pacific/Galapagos</div>
                          <div class="item" data-value="Pacific/Gambier">Pacific/Gambier</div>
                          <div class="item" data-value="Pacific/Guadalcanal">Pacific/Guadalcanal</div>
                          <div class="item" data-value="Pacific/Guam">Pacific/Guam</div>
                          <div class="item" data-value="Pacific/Honolulu">Pacific/Honolulu</div>
                          <div class="item" data-value="Pacific/Johnston">Pacific/Johnston</div>
                          <div class="item" data-value="Pacific/Kiritimati">Pacific/Kiritimati</div>
                          <div class="item" data-value="Pacific/Kosrae">Pacific/Kosrae</div>
                          <div class="item" data-value="Pacific/Kwajalein">Pacific/Kwajalein</div>
                          <div class="item" data-value="Pacific/Majuro">Pacific/Majuro</div>
                          <div class="item" data-value="Pacific/Marquesas">Pacific/Marquesas</div>
                          <div class="item" data-value="Pacific/Midway">Pacific/Midway</div>
                          <div class="item" data-value="Pacific/Nauru">Pacific/Nauru</div>
                          <div class="item" data-value="Pacific/Niue">Pacific/Niue</div>
                          <div class="item" data-value="Pacific/Norfolk">Pacific/Norfolk</div>
                          <div class="item" data-value="Pacific/Noumea">Pacific/Noumea</div>
                          <div class="item" data-value="Pacific/Pago_Pago">Pacific/Pago_Pago</div>
                          <div class="item" data-value="Pacific/Palau">Pacific/Palau</div>
                          <div class="item" data-value="Pacific/Pitcairn">Pacific/Pitcairn</div>
                          <div class="item" data-value="Pacific/Pohnpei">Pacific/Pohnpei</div>
                          <div class="item" data-value="Pacific/Ponape">Pacific/Ponape</div>
                          <div class="item" data-value="Pacific/Port_Moresby">Pacific/Port_Moresby</div>
                          <div class="item" data-value="Pacific/Rarotonga">Pacific/Rarotonga</div>
                          <div class="item" data-value="Pacific/Saipan">Pacific/Saipan</div>
                          <div class="item" data-value="Pacific/Samoa">Pacific/Samoa</div>
                          <div class="item" data-value="Pacific/Tahiti">Pacific/Tahiti</div>
                          <div class="item" data-value="Pacific/Tarawa">Pacific/Tarawa</div>
                          <div class="item" data-value="Pacific/Tongatapu">Pacific/Tongatapu</div>
                          <div class="item" data-value="Pacific/Truk">Pacific/Truk</div>
                          <div class="item" data-value="Pacific/Wake">Pacific/Wake</div>
                          <div class="item" data-value="Pacific/Wallis">Pacific/Wallis</div>
                          <div class="item" data-value="Pacific/Yap">Pacific/Yap</div>
                          <div class="item" data-value="Poland">Poland</div>
                          <div class="item" data-value="Portugal">Portugal</div>
                          <div class="item" data-value="ROC">ROC</div>
                          <div class="item" data-value="ROK">ROK</div>
                          <div class="item" data-value="Singapore">Singapore</div>
                          <div class="item" data-value="Turkey">Turkey</div>
                          <div class="item" data-value="UCT">UCT</div>
                          <div class="item" data-value="US/Alaska">US/Alaska</div>
                          <div class="item" data-value="US/Aleutian">US/Aleutian</div>
                          <div class="item" data-value="US/Arizona">US/Arizona</div>
                          <div class="item" data-value="US/Central">US/Central</div>
                          <div class="item" data-value="US/East-Indiana">US/East-Indiana</div>
                          <div class="item" data-value="US/Eastern">US/Eastern</div>
                          <div class="item" data-value="US/Hawaii">US/Hawaii</div>
                          <div class="item" data-value="US/Indiana-Starke">US/Indiana-Starke</div>
                          <div class="item" data-value="US/Michigan">US/Michigan</div>
                          <div class="item" data-value="US/Mountain">US/Mountain</div>
                          <div class="item" data-value="US/Pacific">US/Pacific</div>
                          <div class="item" data-value="US/Pacific-New">US/Pacific-New</div>
                          <div class="item" data-value="US/Samoa">US/Samoa</div>
                          <div class="item" data-value="UTC">UTC</div>
                          <div class="item" data-value="Universal">Universal</div>
                          <div class="item" data-value="W-SU">W-SU</div>
                          <div class="item" data-value="WET">WET</div>
                          <div class="item" data-value="Zulu">Zulu</div>
                        </div>
                      </div>
                    </div>
                    <div class="field">
                      <label>Sprache</label>
                      <div class="ui fluid selection dropdown" tabindex="0">
                        <input type="hidden" name="language" value="de" />
                        <i class="dropdown icon"></i>
                        <div class="text"><i class="de flag"></i>de</div>

                        <div class="menu" tabindex="-1">
                          <div class="item active selected" data-value="de"><i class="de flag"></i>de</div>

                        </div>

                      </div>

                    </div>
                    <div class="ui hidden divider"></div>
                    <button class="ui submit blue right floated button" type="submit">Speichern</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="ui container mt-5">
        <div class="left aligned sixteen wide column">
          <div class="column">
            <div class="ui piled segment">
              <h2 class="ui blue ribbon label">Benutzeridentität Genehmigung</h2>
              <div class="ui identity-upload segment">
                <form class="ui identity-form form" method="POST" data-action="upload" enctype="multipart/form-data">
                  <div class="ui error message"></div>
                  <div class="ui result message"></div>
                  <div class="ui stackable one column grid">
                    <div class="column">
                      <p>Bitte überprüfen Sie Ihre Identität, indem Sie das Bild des Identitätsdokuments hochladen, das Ihnen gehört, wie Führerschein, Passaport oder Personalausweis. Bitte beachten Sie, dass das Bildformat eines der jpg, jpg oder png sein muss.</p>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label>Identitätsdokument Bild</label>

                        <div class="ui placeholder segment">
                          <div class="ui icon header">
                            <i class="pdf file outline icon"></i>
                            No documents are listed for this customer.
                          </div>
                          <input type="file" name="identity" class="ui primary button" />
                        </div>
                      </div>
                      <div class="column">
                        <div class="ui hidden divider"></div>
                        <div class="ui submit blue right floated button">Hochladen</div>
                      </div>
                    </div>

                  </div></form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui inverted vertical footer segment">
    <div class="ui center aligned container">
        <div class="ui inverted section divider"></div>
        <div class="tablet-and-below-center column"><h4 class="ui inverted copyright header"><img src={footer} class="ui tinyup image"/>
        FGI Financial Depot. I ® Registered Trademark. © 2022. Alle Rechte vorbehalten.
      <div class="ui inverted section divider"></div>
    </h4></div>


</div></div>
    </>
  )
}

export default Profile
