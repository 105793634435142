import React, { useEffect } from "react";

import {MarketData } from "react-ts-tradingview-widgets";

function Last() {
useEffect(()=>{
    deleteItem()
},[])
    function deleteItem(){
        const element = document.getElementById('tradingview_widget_wrapper');
        
        const secondChild = element.children[1].classList.add("removve-item");
        
    }

    return (
     
           
            <div className="container">
          
                <div className="mt-2 "><MarketData  colorTheme="light" width="100%" height={400}></MarketData></div>
            </div>
      
    )
}

export default Last