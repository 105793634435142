import axios from "axios";
import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import URL from "../../config/config.json"


function VerifyEmail() {
    const [code,setCode]=useState();
    function verify_email(){
        axios.post(URL[0].verify,{
            code:code
        }).then(res=>{
           
            if(res.status===200){
                window.alert("Successfully")
            }
            window.location.assign("login")
          
        }).catch(err=>{
            if(err.response.status===400){
                window.alert("Incorrect code. Please try again ")
            }
        })
    }

    return (
        <div className="">
            <Navbar />
            <div className="container d-flex justify-content-center">
            <div className="col-sm-6 mt-5">
            <form onSubmit={(e)=>e.preventDefault()}>
                <div class="form-group">
                    <label for="exampleInputEmail1">Onay Kodu</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Kodu Giriniz"  onChange={(e)=>setCode(e.target.value)}/>
                    
                </div>
                <button type="submit" class="btn btn-primary" onClick={()=>verify_email()}>Onay gönder</button>
            </form>
            </div>
            </div>
          
        </div>
    )
}

export default VerifyEmail