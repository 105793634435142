import axios from "axios";
import React from "react";
import Navbar from "../components/Navbar/Navbar";
import URL from "../config/config.json"
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../src/assets/images/logo-blue.png"
function Login() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    function getLogin() {
        axios.post(URL[0].loginUrl, {
            user: {
                email: email,
                password: password
            }
        }).then(res => {

            if (res.status === 200) {
                toast.success("Login successful");
                localStorage.setItem("isLogin", true);
                localStorage.setItem("tkn", res.data.user.token);
                setTimeout(() => {
                    window.location.assign("/profile");
                }, 2000);
            }
        })


    }


    return (
        <>
            <ToastContainer />
            <Navbar />
            <div class="full-screen-background">
                <div class="ui middle aligned center aligned grid centered-container">
                    <div class="column">
                        <form id="form-login" class="ui large form" onSubmit={(e)=>e.preventDefault()}>
                            <div class="ui stacked segment">
                                <h2 class="ui blue image header">
                                    <center><a class="ui image" href="/"><img src={logo}/></a></center>
                                    <div class="content">
                                        FGI Financial Depot            <div class="sub header">Loggen Sie sich in Ihr Konto ein.</div>
                                    </div>
                                </h2>
                                <div class="field">
                                    <div class="ui left icon input">
                                        <i class="mail icon"></i>
                                        <input type="text" name="email" placeholder="E-mail-Adresse" onChange={(e)=>setEmail(e.target.value)}/>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="ui left icon input">
                                        <i class="key icon"></i>
                                        <input type="password" name="password" placeholder="Passwort" onChange={(e)=>setPassword(e.target.value)}/>
                                    </div>
                                </div>
                                <button type="submit" class="ui fluid large blue submit button" onClick={()=>getLogin()}>Login</button>
                                <p></p>
                                <p><i class="lock icon"></i> Alle Daten werden sicher und verschlüsselt übertragen.</p>
                            </div>
                            <div class="ui error message"></div>
                        </form>
                        <div class="ui message">
                            <span>Sie haben noch kein Konto? </span><a href="/signup">Jetzt Registrieren</a>
                        </div>
                    </div>
                </div>  </div>
        </>
    )
}

export default Login