import React, { useState } from 'react'
import { FaAngleDoubleDown } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { FaChartLine,FaMoneyBill, FaSuitcase } from 'react-icons/fa';
import avatar from "../../assets/images/default_avatar.png"

function Navbar() {
  const isLogin = localStorage.getItem("isLogin");
  const balance = localStorage.getItem("balance");
  const username= localStorage.getItem("username");
  const[show,setShow]=useState(false)

  function logout() {
    localStorage.setItem("isLogin", false);
    localStorage.removeItem("tkn");
    setTimeout(() => {
      window.location.assign("/");
    }, 1000)
  }
  return (
    <div id="main-menu" class="ui blue borderless blue inverted secondary menu">
    <div class="ui container">
      <a class=" item" href="/"><FaChartLine className='me-2'/>  Märkte</a>
      <a class=" item" href="/trade"><FaMoneyBill className='me-2' />Handel</a>
      <a class=" item" href="/portfolio"><FaSuitcase  className='me-2'/> Portfolio</a>
    
      
   
              <div class="right menu">
                <div class="ui inline top right secondary dropdown" tabindex="0">
                  <div class="item">
                   <div class="ui sub header">
                    <div class="ui large celled list">
                      {isLogin==="true"? <div class="ui left labeled button" tabindex="0">
                        <span class="ui basic label">
                          {username}
                        </span>
                        <div class="ui icon button">
                        <FaAngleDoubleDown size={24} onClick={()=>{show===true?setShow(false):setShow(true)}} />
                        </div>
                      </div>:<div className='nvm  navbar-nav ms-auto 'style={{display:"flex",flexDirection:"row"}}>
              <Link to="/login" >  <a className=' nav-link  nvborder btn btn1 me-2' style={{color:"white"}} href='Login'>Login</a></Link>
              <Link to="/register">  <a className=' nav-link btn1 btn nvborder'  style={{color:"white"}} href='register'>Register</a></Link>
            </div>
                        
                    }
                  
                  </div>
              </div>
          </div>
          {show===true?
          
          <div class="menu transition visible" tabindex="-1" style={{display:"block !important"}}>
            
          <div class="avatar-image">
                      <center><img class="ui small bordered image" src={avatar} /></center>
          
                </div>

      
        
                    <div class="header">
              <div class="ui labeled button" tabindex="0">
                <div class="ui blue button">
                  Balance
                </div>
                <a class="ui basic blue left pointing label">
                 {Number(balance).toFixed(2)} EUR
                </a>
              </div>
          </div>
                  <a class="item" href="/profile">
          <i class="user icon"></i>Profil
        </a>                                             
        <a class="item" onClick={()=>logout()}>

         Abmelden
        </a>
      </div>:null}
        </div>
      </div>
    </div>
  </div>
  )
}

export default Navbar