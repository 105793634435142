import React, { useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import First from "../components/TradingView/1";
import Middle from "../components/TradingView/2";
import Last from "../components/TradingView/3";
import footer from "../assets/images/footer.png";
import { FaChartLine } from 'react-icons/fa';
function Home() {
    useEffect(() => {
        // deleteItem()
    }, [])
    function deleteItem() {
        const element = document.getElementById('tradingview_widget_wrapper');

        const secondChild = element.children[1].classList.add("removve-item");

    }

    return (
        <div>
            <Navbar />
            <div id="content-container">
                <div id="markets" class="ui container">
                    <div class="ui stackable one column grid">

                        <div class="left aligned sixteen wide column">
                            <div class="column">
                                <div class="ui piled segment">
                                    <span class="ui blue ribbon label"><FaChartLine /> Märkte</span>
                                    <span>Fortschrittliche Chart-Widgets in Echtzeit</span>

                                   <First/>
                                </div>
                            </div>
                        </div>


                        <div class="left aligned sixteen wide column">
                            <div class="column">
                                <div class="ui piled segment">
                                    <span class="ui blue ribbon label"><FaChartLine /> Märkte</span>
                                    <span>Fortschrittliche Chart-Widgets in Echtzeit</span>
                                    <Middle/>

                                </div>
                            </div>
                        </div>
                        <div class="left aligned sixteen wide column">
                            <div class="column">
                                <div class="ui piled segment">
                                    <span class="ui blue ribbon label">Märkte</span>
                                    <span>Fortschrittliche Chart-Widgets in Echtzeit</span>
                                    <Last/>

                                </div>
                            </div>
                        </div>






                    </div>  </div>
                <div class="ui inverted vertical footer segment">
                    <div class="ui center aligned container">
                        <div class="ui inverted section divider"></div>
                        <div class="tablet-and-below-center column"><h4 class="ui inverted copyright header"><img src={footer} class="ui tinyup image" />
                            FGI Financial Depot. I ® Registered Trademark. © 2022. Alle Rechte vorbehalten.
                            <div class="ui inverted section divider"></div>
                        </h4></div>


                    </div></div></div>


        </div>
    )
}

export default Home